import { Drawer, Heading, Popup } from "@maistro/components";
import EditUserForm from "features/company/profile/users/EditUserForm";
import { Formik } from "formik";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyUserDto } from "types/dtos/company/CompanyUserDto";
import RolesEnum from "types/enums/rolesPermissions/RolesEnum";
import * as Yup from "yup";

import UserSavingContext from "features/company/profile/users/contexts/UserSavingContext";
import ReactGA from "react-ga4";
import { createUseStyles } from "react-jss";
import { ITheme } from "styles/themes/types";
import { RegexEmail } from "types/consts/regexConstants";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    drawerHeading: {
        marginBottom: theme.spacing.large,
    },
}));

interface IEditUserDrawer {
    isOpen: boolean;
    isCreateMode: boolean;
    onClose: () => void;
    onSubmit: (user: CompanyUserDto) => void;
    selectedUser: CompanyUserDto | null;
    companyIsBuyer: boolean;
    companyServiceOrientationTypeId: number | undefined;
}

const EditUserDrawer: React.FC<IEditUserDrawer> = ({
    onSubmit,
    isOpen,
    onClose,
    selectedUser,
    companyIsBuyer,
    companyServiceOrientationTypeId,
    isCreateMode,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    let isValidDialCode = true;
    let isValidLength = true;
    const [warnUnsavedChanges, setWarnUnsavedChanges] = useState(false);
    const [showUnsavedChangesPopup, setShowUnsavedChangesPopup] = useState(false);
    const availableRoles = useMemo(() => {
        if (!companyIsBuyer) {
            return [RolesEnum.Supplier];
        }

        const basicRoles = [RolesEnum.CompanyAdmin, RolesEnum.BuyerReadOnly, RolesEnum.User];
        const techRoles = [RolesEnum.TechnologyCompanyAdmin, RolesEnum.TechnologyUser];
        const fullServiceRoles = [RolesEnum.FullServiceCompanyAdmin, RolesEnum.FullServiceUser];

        switch (companyServiceOrientationTypeId) {
            case 0:
                return basicRoles;
            case 1:
                return techRoles;
            case 2:
                if (isCreateMode || !selectedUser?.roleName) {
                    return [...basicRoles, ...techRoles, ...fullServiceRoles];
                }

                if (basicRoles.includes(selectedUser.roleName)) {
                    return [...basicRoles, ...fullServiceRoles];
                }

                if (techRoles.includes(selectedUser.roleName)) {
                    return [...techRoles, ...fullServiceRoles];
                }

                return fullServiceRoles;
            default:
                return [RolesEnum.Supplier];
        }
    }, [companyIsBuyer, companyServiceOrientationTypeId, isCreateMode, selectedUser]);

    const initialRole = availableRoles.length > 1 ? null : availableRoles[0];
    const isSaving = useContext(UserSavingContext);

    const onCloseDrawer = (): void => {
        if (warnUnsavedChanges) {
            setShowUnsavedChangesPopup(true);
        } else {
            onClose();
        }
    };

    const validationSchema = () =>
        Yup.object({
            firstName: Yup.string()
                .required(t("companyProfileUsers.form.firstName.required"))
                .max(100, t("companyProfileUsers.form.firstName.max")),
            lastName: Yup.string()
                .required(t("companyProfileUsers.form.lastName.required"))
                .max(100, t("companyProfileUsers.form.lastName.max")),
            jobTitle: Yup.string().max(256, t("companyProfileUsers.form.jobTitle.max")),
            emailAddress: Yup.string()
                .required(t("companyProfileUsers.form.emailAddress.required"))
                .matches(new RegExp(RegexEmail), t("companyProfileUsers.form.emailAddress.invalid"))
                .max(256, t("companyProfileUsers.form.emailAddress.max")),
            roleName: Yup.string().nullable().required(t("companyProfileUsers.form.roleName.required")),
            phoneNumber: Yup.string()
                .test("validDialCode", t("companyProfileUsers.form.phoneNumber.invalid"), () => isValidDialCode)
                .test("validLength", t("companyProfileUsers.form.phoneNumber.min"), () => isValidLength),
        });

    const UnsavedChangesWarning = (props: { dirty: boolean }) => {
        useEffect(() => {
            setWarnUnsavedChanges(props.dirty && !isSaving);
        }, [props]);
        return null;
    };

    return (
        <>
            <Drawer isOpen={isOpen} onClose={onCloseDrawer} testid="edit-user-drawer">
                <Heading variant="h2" className={classes.drawerHeading}>
                    {selectedUser === null ? t("companyProfileUsers.addUser") : t("companyProfileUsers.editUser")}
                </Heading>
                <Formik
                    initialValues={{
                        userUuid: selectedUser?.userUuid ?? "",
                        firstName: selectedUser?.firstName ?? "",
                        lastName: selectedUser?.lastName ?? "",
                        emailAddress: selectedUser?.emailAddress ?? "",
                        jobTitle: selectedUser?.jobTitle ?? "",
                        phoneNumber: selectedUser?.phoneNumber ?? "",
                        roleName: selectedUser === null ? initialRole : selectedUser.roleName,
                        status: null,
                        lastLoggedIn: null,
                    }}
                    onSubmit={(values) => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: `Company - Save User - ${values.firstName} ${values.lastName}`,
                        });
                        onSubmit(values);
                    }}
                    onReset={onCloseDrawer}
                    validationSchema={validationSchema}
                >
                    {({ dirty }) => {
                        return (
                            <>
                                <EditUserForm
                                    availableRoles={availableRoles}
                                    setIsValidPhoneNumber={(isDialCode: boolean, isLength: boolean) => {
                                        isValidDialCode = isDialCode;
                                        isValidLength = isLength;
                                    }}
                                    isCreateMode={isCreateMode}
                                />
                                <UnsavedChangesWarning dirty={dirty} />
                            </>
                        );
                    }}
                </Formik>
            </Drawer>
            <Popup
                isOpen={showUnsavedChangesPopup}
                onClose={() => setShowUnsavedChangesPopup(false)}
                testid="unsaved-changes-warning-popup"
                title={t("companyProfileUsers.popups.unsavedChanges.title")}
                message={t("companyProfileUsers.popups.unsavedChanges.message")}
                primaryActionText={t("companyProfileUsers.popups.unsavedChanges.primaryActionText")}
                secondaryActionText={t("companyProfileUsers.popups.unsavedChanges.secondaryActionText")}
                onPrimaryAction={() => {
                    setShowUnsavedChangesPopup(false);
                    onClose();
                }}
                onSecondaryAction={() => setShowUnsavedChangesPopup(false)}
            />
        </>
    );
};

export default EditUserDrawer;

import { useToaster } from "@maistro/components";
import { searchTaxonomy } from "api/taxonomyApi";
import { useTranslation } from "react-i18next";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import CategorySelectionStatus from "types/enums/CategorySelectionStatus";

const useCompanyServices = () => {
    const { t } = useTranslation();
    const toast = useToaster();

    const fetchTaxonomyOptions = (category?: string) => async (searchParameter?: string) => {
        if (searchParameter && searchParameter.length > 0) {
            const response = await searchTaxonomy(searchParameter, category);

            if (response.data instanceof TransactionErrorDto) {
                toast.error(t("companyServices.api.failTaxonomySearch"));
                return [];
            }

            return response.data._embedded.items.map((item) => ({
                value: item._embedded.uuid,
                label: item._embedded.hierarchyDisplayName,
                status: CategorySelectionStatus.Manual,
            }));
        }

        return [];
    };

    return { fetchTaxonomyOptions };
};

export default useCompanyServices;

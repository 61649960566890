import { Button, DateField, Heading, SelectField, TextButton, TextField } from "@maistro/components";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { ICommonProps } from "components/shared";
import useDuplicateProject from "features/dashboard/duplicate/hooks/useDuplicateProject";
import { IDuplicateProjectInformation } from "features/dashboard/interfaces/IDuplicateProjectInformation";
import { IProjectDashboardInformation } from "features/dashboard/interfaces/IProjectDashboardInformation";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { ITheme } from "styles/themes/types";
import ServiceOrientationType from "types/enums/companies/ServiceOrientationType";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectType from "types/enums/projects/ProjectType";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const useStyles = createUseStyles((theme: ITheme) => ({
    inputContainer: {
        width: "100%",
        marginBottom: theme.spacing.medium,
    },
    heading: {
        marginBottom: theme.spacing.medium,
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.small,
        paddingTop: theme.spacing.small,
    },
}));

interface IDuplicateProjectFormProps extends ICommonProps {
    onCancel: () => void;
    onCreate: (values: IDuplicateProjectInformation) => void;
    isDuplicating: boolean;
    duplicateFromProject: IProjectDashboardInformation;
}

const DuplicateProjectForm: React.FC<IDuplicateProjectFormProps> = (props) => {
    const classes = useStyles();

    const { duplicateFromProject } = props;

    const { t } = useTranslation();

    const { userHasPermission, myCompanyUuid } = useCurrentUser();
    const { clients, getClients, sponsors, getSponsors } = useDuplicateProject();

    const userIsSupport = userHasPermission(PermissionsEnum.ManageSupport);
    const isTender = duplicateFromProject.projectType === ProjectType.Tender;

    const serviceOrientationTypeIds = useMemo(() => {
        return duplicateFromProject.projectType === ProjectType.TechnologyQuote
            ? [ServiceOrientationType.Technology, ServiceOrientationType.FullService]
            : [ServiceOrientationType.Standard, ServiceOrientationType.FullService];
    }, [duplicateFromProject.projectType]);

    useEffect(() => {
        if (!userIsSupport) {
            getSponsors(myCompanyUuid);
        }
    }, [getSponsors, myCompanyUuid, userIsSupport]);

    useEffect(() => {
        if (userIsSupport) {
            getClients("", serviceOrientationTypeIds);
        }
    }, [getClients, serviceOrientationTypeIds, userIsSupport]);

    const validationSchema = () =>
        Yup.object({
            name: Yup.string().required(t("projectBrief.name.required")),
            sponsorUserUuid: Yup.string().when([], {
                is: () => isTender,
                then: (schema) => schema.required(t("projectBrief.sponsor.required")),
                otherwise: (schema) => schema.notRequired(),
            }),
            tenderResponseDeadline: Yup.date()
                .nullable()
                .typeError(t("dashboard.projects.duplicate.validation.tenderResponseDeadlineRequired"))
                .required(t("dashboard.projects.duplicate.validation.tenderResponseDeadlineRequired"))
                .min(new Date(), t("dashboard.projects.duplicate.validation.invalidTenderResponseDeadline-inPast")),
            clarificationQuestionDeadline: Yup.date()
                .when([], (__, schema) => {
                    if (isTender) {
                        return schema
                            .nullable()
                            .required(
                                t("dashboard.projects.duplicate.validation.clarificationQuestionDeadlineRequired"),
                            );
                    }
                    return schema.nullable().notRequired();
                })
                .min(
                    new Date(),
                    t("dashboard.projects.duplicate.validation.invalidClarificationQuestionDeadline-inPast"),
                )
                .when("tenderResponseDeadline", ([tenderResponseDeadline], schema) => {
                    if (tenderResponseDeadline) {
                        // max date is technically the day before the tenderResponseDeadline
                        const dayBefore = new Date(tenderResponseDeadline.getTime() - 86400000);
                        return schema.max(
                            dayBefore,
                            t(
                                "dashboard.projects.duplicate.validation.invalidClarificationQuestionDeadline-tenderResponse",
                            ),
                        );
                    }

                    return schema;
                }),
            estimatedContractStart: Yup.date()
                .when([], (__, schema) => {
                    if (isTender) {
                        return schema
                            .nullable()
                            .required(t("dashboard.projects.duplicate.validation.contractStartDateRequired"));
                    }
                    return schema.nullable().notRequired();
                })
                .min(
                    Yup.ref("tenderResponseDeadline"),
                    t("dashboard.projects.duplicate.validation.invalidEstimatedContractStart"),
                ),
            estimatedContractEnd: Yup.date()
                .nullable()
                .min(
                    Yup.ref("estimatedContractStart"),
                    t("dashboard.projects.duplicate.validation.invalidEstimatedContractEnd"),
                ),
        });

    const globalAdminValidationSchema = () =>
        validationSchema().concat(
            Yup.object({
                clientCompanyUuid: Yup.string().nullable().required(t("projectBrief.client.required")),
            }),
        );

    return (
        <Formik
            initialValues={{
                name: "",
            }}
            onSubmit={(values) =>
                props.onCreate({
                    projectUuid: duplicateFromProject.projectUuid,
                    previousName: duplicateFromProject.projectName,
                    isTender,
                    ...values,
                })
            }
            onReset={() => {
                props.onCancel();
            }}
            validationSchema={userIsSupport ? globalAdminValidationSchema() : validationSchema()}
        >
            {({ errors, dirty }) => (
                <Form>
                    <Heading className={classes.heading} variant="h2">
                        {t("dashboard.projects.duplicate.title")}
                    </Heading>
                    <div className={classes.inputContainer}>
                        <TextField
                            testid="duplicate-project-name"
                            label={t("projectBrief.name.label")}
                            name="name"
                            required
                            tooltip={t("dashboard.projects.duplicate.tooltips.projectName")}
                        />
                    </div>
                    {userIsSupport && (
                        <div className={classes.inputContainer}>
                            <SelectField
                                label={t("projectBrief.client.label")}
                                name="clientCompanyUuid"
                                required
                                tooltip={t("projectBrief.client.tooltip")}
                                options={clients}
                                onChange={(value) => getSponsors(value)}
                                onInputChange={(value) => getClients(value, serviceOrientationTypeIds)}
                                testid="duplicate-project-client"
                            />
                        </div>
                    )}
                    <div className={classes.inputContainer}>
                        <SelectField
                            label={t("projectBrief.sponsor.label")}
                            name="sponsorUserUuid"
                            required={isTender}
                            tooltip={t("projectBrief.sponsor.tooltip")}
                            options={sponsors}
                            resetIfOptionsChange
                            testid="duplicate-project-sponsor"
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <DateField
                            name="tenderResponseDeadline"
                            required
                            label={t("dashboard.projects.duplicate.tenderResponseDeadline")}
                            tooltip={
                                isTender
                                    ? t("projectSummary.tender.fields.projectResponseDate.tooltip")
                                    : t("projectSummary.quote.fields.projectResponseDate.tooltip")
                            }
                            withTime
                            testid="duplicate-project-tender-response-deadline"
                        />
                    </div>
                    {isTender && (
                        <React.Fragment>
                            <div className={classes.inputContainer}>
                                <DateField
                                    testid="duplicate-project-clarification-question-deadline"
                                    name="clarificationQuestionDeadline"
                                    required={isTender}
                                    label={t("dashboard.projects.duplicate.clarificationQuestionDeadline")}
                                    tooltip={t("dashboard.projects.duplicate.tooltips.clarificationQuestionDeadline")}
                                />
                            </div>
                            <div className={classes.inputContainer}>
                                <DateField
                                    testid="duplicate-project-estimated-contract-start"
                                    name="estimatedContractStart"
                                    required={isTender}
                                    label={t("dashboard.projects.duplicate.estimatedContractStart")}
                                    tooltip={t("dashboard.projects.duplicate.tooltips.estimatedContractStart")}
                                />
                            </div>
                            <div className={classes.inputContainer}>
                                <DateField
                                    testid="duplicate-project-estimated-contract-end"
                                    name="estimatedContractEnd"
                                    label={t("dashboard.projects.duplicate.estimatedContractEnd")}
                                    tooltip={t("dashboard.projects.duplicate.tooltips.estimatedContractEnd")}
                                />
                            </div>
                        </React.Fragment>
                    )}
                    <div className={classes.buttonControl}>
                        <Button
                            testid="duplicate-project-create-button"
                            type="submit"
                            label={t("dashboard.projects.duplicate.create")}
                            disabled={props.isDuplicating || !isEmpty(errors) || !dirty}
                            onClick={() =>
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Project Duplicate Submit - ".concat(duplicateFromProject.projectName),
                                })
                            }
                        />
                        <TextButton
                            testid="duplicate-project-cancel-button"
                            type="reset"
                            label={t("common.cancel")}
                            onClick={() =>
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Project Duplicate Cancel - ".concat(duplicateFromProject.projectName),
                                })
                            }
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default DuplicateProjectForm;

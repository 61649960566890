import { ReadOnlyField } from "@maistro/components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import FileList from "components/FileList/FileList";
import { IProjectResponseInformation } from "features/project/types";
import currencyService from "services/currencyService";
import dateTimeService from "services/dateTimeService";
import { ITheme } from "styles/themes/types";
import FileDto from "types/dtos/files/FileDto";
import BudgetType from "types/enums/projects/BudgetType";
import ProjectType from "types/enums/projects/ProjectType";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    categories: {
        margin: `${theme.spacing.xSmall / 3}px ${theme.spacing.xSmall / 3}px`,
    },
    content: {
        overflow: "auto",
    },
}));

interface IProjectResponseBriefDisplay {
    projectResponseInformation: IProjectResponseInformation;
    projectFiles?: FileDto[];
}

const ProjectResponseBriefDisplay: React.FC<IProjectResponseBriefDisplay> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const { projectResponseInformation, projectFiles } = props;

    const projectUuid = useMemo(() => {
        if ("projectUuid" in projectResponseInformation) return projectResponseInformation.projectUuid;

        return undefined;
    }, [projectResponseInformation]);

    const renderBudget = () => {
        if (projectResponseInformation.budgetType !== BudgetType.Budget) {
            return (
                <ReadOnlyField
                    testid="project-budget"
                    label={t("projectBrief.buyerBudget.label")}
                    value={t(`budgetTypes.names.${projectResponseInformation.budgetType}`)}
                />
            );
        }

        if (
            projectResponseInformation.budgetType === BudgetType.Budget &&
            projectResponseInformation.showBudgetToSupplier
        ) {
            return (
                <ReadOnlyField
                    testid="project-budget"
                    label={t("projectBrief.buyerBudget.label")}
                    value={`${currencyService.toCurrencyIcon(projectResponseInformation.budgetCurrency)}
                ${projectResponseInformation.buyerBudget.toLocaleString()}`}
                />
            );
        }

        return (
            <ReadOnlyField
                testid="project-budget"
                label={t("projectBrief.buyerBudget.label")}
                value={t("budgetTypes.names.Unknown")}
            />
        );
    };

    return (
        <div className={classes.container}>
            <ReadOnlyField
                testid="project-name"
                label={t("projectBrief.name.label")}
                value={projectResponseInformation.projectName}
            />
            <ReadOnlyField
                testid="company-name"
                label={t("projectBrief.company.label")}
                value={projectResponseInformation.companyName}
            />
            <ReadOnlyField
                testid="project-manager"
                label={t("projectBrief.manager.label")}
                value={projectResponseInformation.manager}
            />
            {renderBudget()}
            {projectResponseInformation.tenderResponseDeadline && (
                <ReadOnlyField
                    testid="project-response-date"
                    label={t("projectBrief.responseDate.label")}
                    value={dateTimeService.toShortDateTimeFormat(projectResponseInformation.tenderResponseDeadline)}
                />
            )}
            {projectResponseInformation.clarificationQuestionDeadline && (
                <ReadOnlyField
                    testid="clarification-question-deadline"
                    label={t("projectSummary.tender.fields.clarificationQuestionDeadline.label")}
                    value={dateTimeService.toShortDateFormat(projectResponseInformation.clarificationQuestionDeadline)}
                />
            )}
            {projectResponseInformation.targetProjectStartDate && (
                <ReadOnlyField
                    testid="contract-start-date"
                    label={t("projectSummary.tender.fields.contractStartDate.label")}
                    value={dateTimeService.toShortDateFormat(projectResponseInformation.targetProjectStartDate)}
                />
            )}
            {projectResponseInformation.targetProjectCompleteDate && (
                <ReadOnlyField
                    testid="contract-end-date"
                    label={t("projectSummary.tender.fields.contractEndDate.label")}
                    value={dateTimeService.toShortDateFormat(projectResponseInformation.targetProjectCompleteDate)}
                />
            )}
            {[ProjectType.TechnologyQuote, ProjectType.Quote].includes(projectResponseInformation.projectType) && (
                <ReadOnlyField
                    testid="project-timescale"
                    className={classes.content}
                    label={t("projectBrief.timescale.label")}
                    value={projectResponseInformation.timescale}
                />
            )}
            <ReadOnlyField
                testid="project-description"
                className={classes.content}
                label={t("projectBrief.description.label")}
                value={projectResponseInformation.description}
            />
            {projectUuid && projectFiles && projectFiles.length > 0 && (
                <ReadOnlyField
                    testid="project-files"
                    label={t("projectBrief.fileList.label")}
                    value={<FileList files={projectFiles} canDownload testid="project-documents" />}
                />
            )}
        </div>
    );
};

export default ProjectResponseBriefDisplay;

import { ITableColumn, TextButton, Tooltip } from "@maistro/components";
import classNames from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { MaistroRating } from "components";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import currencyService from "services/currencyService";
import { ITheme } from "styles/themes/types";
import { SupplierSubmissionResponseDto } from "types/dtos/projects/supplierSubmissions/SupplierSubmissionResponseDto";
import ProjectType from "types/enums/projects/ProjectType";

const useStyles = createUseStyles((theme: ITheme) => ({
    nameRatingDocuments: {
        display: "grid",
        gridTemplateAreas: '"name" "matchRating"',
        alignItems: "center",
        justifyContent: "space-between",
    },
    icon: {
        gridArea: "icon",
    },
    name: {
        gridArea: "name",
    },
    matchRating: {
        gridArea: "matchRating",
    },
    supplierListPills: {
        display: "flex",
        gap: theme.spacing.xSmall,
    },
}));

const useAwardSummaryConfiguration = (projectUuid?: string, type?: ProjectType) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const columns: ITableColumn[] = useMemo(() => {
        const columnConfiguration = [
            {
                position: 1,
                priorityLevel: 1,
                label: t("awardSummary.table.supplierName"),
                name: "supplierCompanyName",
                minWidth: 150,
                labelWrap: false,
                render: (supplier: SupplierSubmissionResponseDto) => (
                    <div className={classes.nameRatingDocuments}>
                        <TextButton
                            label={supplier._embedded.supplierCompanyName}
                            className={classNames(classes.name)}
                            onClick={() =>
                                navigate(
                                    buildPath(routes.company.supplierCompanyProfile, {
                                        companyUuid: supplier.supplierCompanyUuid,
                                    }),
                                    {
                                        state: {
                                            route: buildRoute(routes.projects.award, { projectUuid }),
                                        },
                                    },
                                )
                            }
                            testid={`${supplier.supplierCompanyUuid}-name`}
                        />
                        <Tooltip
                            content={t("projectMatchingScreen.tooltips.matchingStars")}
                            testid={`${supplier.supplierCompanyUuid}-rating-tooltip`}
                            hideIcon
                        >
                            <MaistroRating
                                className={classes.matchRating}
                                currentRating={supplier._included.matchRating * 5}
                                testid={`${supplier.supplierCompanyUuid}-match-rating`}
                            />
                        </Tooltip>
                    </div>
                ),
            },
        ];

        if (type === ProjectType.Tender) {
            columnConfiguration.push({
                position: 3,
                priorityLevel: 2,
                label: t("awardSummary.table.score"),
                name: "score",
                minWidth: 100,
                render: (supplier: SupplierSubmissionResponseDto) => <div>{supplier.scoring ?? "-"}</div>,
                labelWrap: false,
            });
        }

        if (type === ProjectType.Quote || type === ProjectType.TechnologyQuote) {
            columnConfiguration.push({
                position: 2,
                priorityLevel: 3,
                label: t("awardSummary.table.totalPrice"),
                name: "totalPrice",
                minWidth: 200,
                render: (supplier: SupplierSubmissionResponseDto) => (
                    <div>
                        {currencyService.toDisplayFormat(
                            supplier.supplierPricing.simplePrice.ccy,
                            supplier.supplierPricing.simplePrice.price,
                        )}
                    </div>
                ),
                labelWrap: false,
            });
        }

        return columnConfiguration;
    }, [classes, navigate, projectUuid, t, type]);

    return { columns };
};

export default useAwardSummaryConfiguration;

import { faEdit, faEye } from "@fortawesome/pro-regular-svg-icons";
import { Icon, ISortBy, ITableColumn, MeatballMenu, Pill, SortOrder } from "@maistro/components";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
    emptyFilters,
    IDashboardFilters,
    IOptionalFilterConfiguration,
} from "features/dashboard/filter/hooks/useDashboardFilters";
import { IProjectDashboardInformation } from "features/dashboard/interfaces/IProjectDashboardInformation";
import calculateEoiUrl from "features/helpers/calculateEoiUrl";
import calculateProjectUrl from "features/helpers/calculateProjectUrl";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import dateTimeService from "services/dateTimeService";
import stringService from "services/stringService";
import editableProjectStatuses from "types/consts/editableProjectStatuses";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import ProjectType from "types/enums/projects/ProjectType";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";

const getPermissionByProjectType = (
    actionPermission: PermissionsEnum,
    techPermission: PermissionsEnum,
    projectType: ProjectType,
) => {
    return projectType === ProjectType.TechnologyQuote ? techPermission : actionPermission;
};

const useDashboardUserConfiguration = (
    setProjectToAction: (project: IProjectDashboardInformation) => void,
    setIsArchivePopupOpen: (isOpen: boolean) => void,
    setIsDuplicateDrawerOpen: (isOpen: boolean) => void,
) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { myUuid, myName, userHasProjectPermission, userIsMaistro } = useCurrentUser();

    const nonArchivableStatuses = useMemo(
        () => [ProjectStatus.Complete, ProjectStatus.CompleteAtReview, ProjectStatus.Results],
        [],
    );
    const eoiNonArchivableStatuses = useMemo(() => [ProjectStatus.Complete], []);
    const nonUpdateableProjectStatuses = useMemo(() => [ProjectStatus.Archived, ProjectStatus.Cancelled], []);

    const navigateToProjectUrl = useCallback(
        (row: IProjectDashboardInformation) => {
            const { projectUuid, projectStatus, projectType } = row;

            if (projectType === ProjectType.ExpressionOfInterest) {
                navigate(calculateEoiUrl(projectUuid, projectStatus));
                return;
            }
            navigate(calculateProjectUrl(projectUuid, projectStatus));
        },
        [navigate],
    );

    const onRowClick = useCallback(
        (row: IProjectDashboardInformation) => {
            navigateToProjectUrl(row);
        },
        [navigateToProjectUrl],
    );

    const calculateProjectIcon = useCallback((row: IProjectDashboardInformation) => {
        if (editableProjectStatuses.find((status) => row.projectStatus === status) != null) {
            return faEdit;
        }

        return faEye;
    }, []);

    const onProjectSettingsClick = useCallback(
        (row: IProjectDashboardInformation) => {
            navigate(buildPath(routes.projects.settings, { projectUuid: row.projectUuid }));
        },
        [navigate],
    );

    const canViewProjectSettings = useCallback(
        (project: IProjectDashboardInformation) =>
            userHasProjectPermission(
                getPermissionByProjectType(
                    PermissionsEnum.UpdateProject,
                    PermissionsEnum.UpdateTechProject,
                    project.projectType,
                ),
                project.projectUuid,
            ),
        [userHasProjectPermission],
    );

    const canArchiveProjects = useCallback(
        (project: IProjectDashboardInformation): boolean =>
            userHasProjectPermission(
                getPermissionByProjectType(
                    PermissionsEnum.ArchiveProject,
                    PermissionsEnum.ArchiveTechProject,
                    project.projectType,
                ),
                project.projectUuid,
            ),
        [userHasProjectPermission],
    );

    const canDuplicateProjects = useCallback(
        (project: IProjectDashboardInformation): boolean =>
            userHasProjectPermission(
                getPermissionByProjectType(
                    PermissionsEnum.DuplicateProject,
                    PermissionsEnum.DuplicateTechProject,
                    project.projectType,
                ),
                project.projectUuid,
            ),
        [userHasProjectPermission],
    );

    const projectCanBeUpdated = useCallback(
        (project: IProjectDashboardInformation) => !nonUpdateableProjectStatuses.includes(project.projectStatus),
        [nonUpdateableProjectStatuses],
    );

    const projectCanBeDuplicated = useCallback(
        (project: IProjectDashboardInformation) =>
            [ProjectType.TechnologyQuote, ProjectType.Quote, ProjectType.Tender].includes(project.projectType),
        [],
    );

    const projectCanBeArchived = useCallback(
        (project: IProjectDashboardInformation) =>
            project.projectType === ProjectType.ExpressionOfInterest
                ? !eoiNonArchivableStatuses.includes(project.projectStatus)
                : !nonArchivableStatuses.includes(project.projectStatus),
        [nonArchivableStatuses, eoiNonArchivableStatuses],
    );

    const onDuplicateProjectClick = useCallback(
        (row: IProjectDashboardInformation) => {
            setProjectToAction(row);
            setIsDuplicateDrawerOpen(true);
        },
        [setIsDuplicateDrawerOpen, setProjectToAction],
    );

    const initialFilters: IDashboardFilters = useMemo(
        () => ({
            ...emptyFilters,
            projectManagers: userIsMaistro ? [{ label: myName, value: myUuid }] : [],
        }),
        [myUuid, myName, userIsMaistro],
    );

    const initialSort: ISortBy = useMemo(
        () => ({
            column: "submissionDate",
            order: SortOrder.Descending,
        }),
        [],
    );

    const menuItemsForProject = useCallback(
        (row: IProjectDashboardInformation) => {
            const actions = [];
            if (canViewProjectSettings(row) && projectCanBeUpdated(row)) {
                actions.push({
                    label: t("dashboard.projects.actions.projectSettings"),
                    action: () => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Project settings - ".concat(row.projectName),
                        });
                        onProjectSettingsClick(row);
                    },
                });
            }
            if (canDuplicateProjects(row) && projectCanBeDuplicated(row)) {
                actions.push({
                    label: t("dashboard.projects.actions.duplicate"),
                    action: () => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Project duplicate - ".concat(row.projectName),
                        });
                        onDuplicateProjectClick(row);
                    },
                });
            }
            if (canArchiveProjects(row) && projectCanBeArchived(row)) {
                actions.push({
                    label:
                        row.projectStatus === ProjectStatus.Archived
                            ? t("dashboard.projects.actions.restore")
                            : t("dashboard.projects.actions.archive"),
                    action: () => {
                        ReactGA.event({
                            category: ga4Category.Button,
                            action: ga4Action.ButtonClick,
                            label: "Project archive - ".concat(row.projectName),
                        });
                        setIsArchivePopupOpen(true);
                        setProjectToAction(row);
                    },
                });
            }

            return actions;
        },
        [
            canArchiveProjects,
            canDuplicateProjects,
            canViewProjectSettings,
            onDuplicateProjectClick,
            onProjectSettingsClick,
            projectCanBeArchived,
            projectCanBeDuplicated,
            projectCanBeUpdated,
            setIsArchivePopupOpen,
            setProjectToAction,
            t,
        ],
    );

    const columns: ITableColumn[] = useMemo(() => {
        if (userIsMaistro) {
            return [
                {
                    position: 1,
                    priorityLevel: 1,
                    label: t("dashboard.projects.header.projectName"),
                    name: "projectName",
                    minWidth: 200,
                    contentWrap: true,
                    sortable: true,
                },
                {
                    position: 2,
                    priorityLevel: 2,
                    label: t("dashboard.projects.header.companyName"),
                    name: "companyName",
                    minWidth: 200,
                    contentWrap: true,
                },
                {
                    position: 3,
                    priorityLevel: 6,
                    label: t("dashboard.projects.header.type"),
                    name: "projectType",
                    minWidth: 100,
                    render: (project: IProjectDashboardInformation) =>
                        t(`common.${stringService.toCamelCase(project.projectType)}`),
                },
                {
                    position: 4,
                    priorityLevel: 8,
                    label: t("dashboard.projects.header.projectId"),
                    name: "projectFriendlyId",
                    minWidth: 150,
                },
                {
                    position: 5,
                    priorityLevel: 4,
                    label: t("dashboard.projects.header.projectManager"),
                    name: "projectManagerName",
                    minWidth: 200,
                },
                {
                    position: 6,
                    priorityLevel: 7,
                    label: t("dashboard.projects.header.submissionDate"),
                    name: "submissionDate",
                    sortable: true,
                    minWidth: 150,
                    render: (project: IProjectDashboardInformation) =>
                        dateTimeService.toShortDateFormat(project.submissionDate),
                },
                {
                    position: 7,
                    priorityLevel: 5,
                    label: t("dashboard.projects.header.responseDeadline"),
                    name: "tenderResponseDeadline",
                    sortable: true,
                    minWidth: 150,
                    render: (project: IProjectDashboardInformation) =>
                        dateTimeService.toShortDateTimeFormatWithoutTz(
                            project.projectType === ProjectType.ExpressionOfInterest
                                ? project.expressionOfInterestDeadline
                                : project.tenderResponseDeadline,
                        ) || "-",
                },
                {
                    position: 8,
                    priorityLevel: 3,
                    label: t("dashboard.projects.header.status"),
                    name: "status",
                    minWidth: 150,
                    render: (project: IProjectDashboardInformation) => (
                        <Pill value={stringService.insertSpacesInTitleCase(project.projectStatus)} />
                    ),
                },
                {
                    position: 9,
                    priorityLevel: 0,
                    name: "action",
                    minWidth: 50,
                    render: (project: IProjectDashboardInformation) => (
                        <Icon
                            icon={calculateProjectIcon(project)}
                            alt={calculateProjectIcon(project) === faEdit ? t("common.edit") : t("common.view")}
                            onClick={() => onRowClick(project)}
                        />
                    ),
                },
                {
                    position: 10,
                    priorityLevel: 0,
                    name: "menu",
                    minWidth: 50,
                    render: (project: IProjectDashboardInformation) => (
                        <MeatballMenu menuItems={menuItemsForProject(project)} />
                    ),
                },
            ];
        }
        return [
            {
                position: 1,
                priorityLevel: 1,
                label: t("dashboard.projects.header.projectName"),
                name: "projectName",
                minWidth: 200,
                contentWrap: true,
            },
            {
                position: 2,
                priorityLevel: 4,
                label: t("dashboard.projects.header.projectManager"),
                name: "projectManagerName",
                minWidth: 200,
            },
            {
                position: 3,
                priorityLevel: 5,
                label: t("dashboard.projects.header.submissionDate"),
                name: "submissionDate",
                sortable: true,
                minWidth: 150,
                render: (project: IProjectDashboardInformation) =>
                    dateTimeService.toShortDateFormat(project.submissionDate),
            },
            {
                position: 4,
                priorityLevel: 3,
                label: t("dashboard.projects.header.responseDeadline"),
                name: "tenderResponseDeadline",
                sortable: true,
                minWidth: 150,
                render: (project: IProjectDashboardInformation) =>
                    dateTimeService.toShortDateTimeFormatWithoutTz(
                        project.projectType === ProjectType.ExpressionOfInterest
                            ? project.expressionOfInterestDeadline
                            : project.tenderResponseDeadline,
                    ) || "-",
            },
            {
                position: 5,
                priorityLevel: 6,
                label: t("dashboard.projects.header.type"),
                name: "projectType",
                minWidth: 100,
                render: (project: IProjectDashboardInformation) =>
                    t(`common.${stringService.toCamelCase(project.projectType)}`),
            },
            {
                position: 6,
                priorityLevel: 2,
                label: t("dashboard.projects.header.status"),
                name: "status",
                minWidth: 150,
                render: (project: IProjectDashboardInformation) => (
                    <Pill value={stringService.insertSpacesInTitleCase(project.projectStatus)} />
                ),
            },
            {
                position: 7,
                priorityLevel: 0,
                name: "action",
                minWidth: 50,
                render: (project: IProjectDashboardInformation) => (
                    <Icon
                        icon={calculateProjectIcon(project)}
                        alt={calculateProjectIcon(project) === faEdit ? t("common.edit") : t("common.view")}
                        onClick={() => onRowClick(project)}
                    />
                ),
            },
            {
                position: 8,
                priorityLevel: 0,
                name: "menu",
                minWidth: 50,
                render: (project: IProjectDashboardInformation) => (
                    <MeatballMenu menuItems={menuItemsForProject(project)} />
                ),
            },
        ];
    }, [calculateProjectIcon, menuItemsForProject, onRowClick, t, userIsMaistro]);

    const optionalFilters: IOptionalFilterConfiguration = useMemo(() => {
        const defaultOptionalFilters = {
            tenderResponseDeadline: true,
            projectManagers: true,
            projectStatuses: true,
        };
        if (userIsMaistro) {
            return {
                ...defaultOptionalFilters,
                companies: true,
            };
        }
        return defaultOptionalFilters;
    }, [userIsMaistro]);

    return {
        sortConfiguration: initialSort,
        filterConfiguration: initialFilters,
        columnsConfiguration: columns,
        optionalFilterConfiguration: optionalFilters,
        onRowClick,
    };
};

export default useDashboardUserConfiguration;

import { useCallback, useState } from "react";

import { IOptionProps } from "components/shared";
import { useLazySearchCompaniesQuery } from "store/api/companiesApi";
import { useLazyListCompanyUsersQuery } from "store/api/companyUsersApi";
import ServiceOrientationType from "types/enums/companies/ServiceOrientationType";

const useDuplicateProject = () => {
    const [clients, setClients] = useState<IOptionProps[]>([]);
    const [sponsors, setSponsors] = useState<IOptionProps[]>([]);

    const [searchCompanies] = useLazySearchCompaniesQuery();
    const [listCompanyUsers] = useLazyListCompanyUsersQuery();

    const getClients = useCallback(
        (searchQuery: string, serviceOrientationTypeIds: ServiceOrientationType[]) => {
            searchCompanies({ companyTypes: ["Buyer"], serviceOrientationTypeIds, searchQuery }, true)
                .unwrap()
                .then((response) => {
                    setClients(
                        response.items
                            .toSorted((a, b) => a.registeredName.localeCompare(b.registeredName))
                            .map((company) => ({
                                value: company.companyUuid,
                                label: company.registeredName,
                            })),
                    );
                });
        },
        [searchCompanies],
    );

    const getSponsors = useCallback(
        (companyUuid?: string) => {
            if (companyUuid) {
                listCompanyUsers({ companyUuid })
                    .unwrap()
                    .then((response) => {
                        const sponsorList: IOptionProps[] = [
                            {
                                value: "",
                                label: "",
                            },
                        ];

                        response.items.forEach((user) => {
                            const fullName = `${user.firstName} ${user.lastName}`;
                            if (fullName.trim()) {
                                sponsorList.push({
                                    value: user.userUuid,
                                    label: fullName,
                                });
                            }
                        });

                        setSponsors(sponsorList);
                    })
                    .catch(() => setSponsors([]));
            }
        },
        [listCompanyUsers],
    );

    return {
        clients,
        getClients,
        sponsors,
        getSponsors,
    };
};

export default useDuplicateProject;

import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { IPaginationState, Popup, useToaster } from "@maistro/components";
import { IButtonProps } from "@maistro/components/dist/esm/types/components/Button/Button";
import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { reinviteCompanyUser } from "api/company/companyApi";
import { Loader } from "components";
import CompanyProfileActionPopup from "features/company/profile/CompanyProfileActionPopup";
import useCompanyStepperNavigation from "features/company/profile/hooks/useCompanyStepperNavigation";
import CompanyUsersDisplay from "features/company/profile/users/CompanyUsersDisplay";
import EditUserDrawer from "features/company/profile/users/EditUserDrawer";
import UserSavingContext from "features/company/profile/users/contexts/UserSavingContext";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { useGetCompanyDetailsQuery } from "store/api/companyApi";
import {
    useCreateCompanyUserMutation,
    useDeleteCompanyUserMutation,
    useLazyListCompanyUsersQuery,
    useUpdateCompanyUserMutation,
} from "store/api/companyUsersApi";
import { resetLayout, setBack, setCtas, setMobileFooterCtas, setPageTitle } from "store/layoutSlice";
import { TransactionErrorException } from "types/consts/apiConstants";
import { CompanyUserDto } from "types/dtos/company/CompanyUserDto";
import { ListCompanyUsersRequestDto } from "types/dtos/company/ListCompanyUsersRequestDto";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";
import RolesEnum from "types/enums/rolesPermissions/RolesEnum";

const CompanyUsersContainer: React.FC = () => {
    const { companyUuid } = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const toast = useToaster();
    const { userHasCompanyPermission, userIsMaistro } = useCurrentUser();

    const defaultPageState = {
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
    };

    const [companyUsers, setCompanyUsers] = useState<CompanyUserDto[]>([]);
    const [paginationState, setPaginationState] = useState<IPaginationState>(defaultPageState);
    const [selectedUser, setSelectedUser] = useState<CompanyUserDto | null>(null);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const [isReinviteUserPopupOpen, setIsReinviteUserPopupOpen] = useState(false);
    const [isReinvitingUser, setIsReinvitingUser] = useState(false);
    const [isDeleteUserPopupOpen, setIsDeleteUserPopupOpen] = useState(false);
    const { data: companyDetails } = useGetCompanyDetailsQuery(companyUuid ?? "");
    const [listCompanyUsers, { isLoading }] = useLazyListCompanyUsersQuery();
    const [updateCompanyUser, { isLoading: isSavingUser }] = useUpdateCompanyUserMutation();
    const [createCompanyUser, { isLoading: isCreatingUser }] = useCreateCompanyUserMutation();
    const [deleteCompanyUser, { isLoading: isDeletingUser, isError: isDeleteError, isSuccess: isDeleteSuccess }] =
        useDeleteCompanyUserMutation();

    useEffect(() => {
        if (isDeleteError) toast.error(t("companyProfileUsers.api.deleteUserError"));
        if (isDeleteSuccess) toast.success(t("companyProfileUsers.api.deleteUserSuccess"));
    }, [isDeleteError, isDeleteSuccess, t, toast]);

    const userCanCreateUsers = useMemo(() => {
        return userIsMaistro || userHasCompanyPermission(PermissionsEnum.CreateUser);
    }, [userIsMaistro, userHasCompanyPermission]);

    const userCanEditUsers = useMemo(() => {
        return userIsMaistro || userHasCompanyPermission(PermissionsEnum.UpdateUser);
    }, [userIsMaistro, userHasCompanyPermission]);

    const adminRoles = (() => {
        if (!companyDetails?.allowBuyerUsers) {
            return [RolesEnum.Supplier];
        }

        switch (companyDetails?.serviceOrientationTypeId) {
            case 0:
                return [RolesEnum.CompanyAdmin];
            case 1:
                return [RolesEnum.TechnologyCompanyAdmin];
            case 2:
                return [RolesEnum.CompanyAdmin, RolesEnum.TechnologyCompanyAdmin, RolesEnum.FullServiceCompanyAdmin];
            default:
                return [];
        }
    })();

    const { isCompanyProfileActionPopupOpen, setIsCompanyProfileActionPopupOpen } = useCompanyStepperNavigation({
        activeStep: "users",
        title: companyDetails?.registeredName,
        subTitle: companyDetails?.netPromoterScore
            ? t("companyProfile.stepperNavigation.netPromoterScore", {
                  netPromoterScore: companyDetails?.netPromoterScore,
              })
            : "",
        companyUuid: companyUuid ?? "",
        isActive: companyDetails?.isActive,
    });

    const noOtherAdmins = useCallback(
        (userUuid: string) => {
            if (companyUsers.length === 0) {
                return false;
            }

            return (
                companyUsers.filter((u) => u.roleName && adminRoles.includes(u.roleName) && u.userUuid !== userUuid)
                    .length === 0
            );
        },
        [companyUsers, adminRoles],
    );

    const navigateToPage = (page: number) => {
        setPaginationState({ ...paginationState, currentPage: page });
    };

    const fetchUsers = useCallback(async () => {
        if (!companyUuid) {
            return;
        }

        listCompanyUsers(
            {
                companyUuid,
                includeUserRoles: true,
                searchQuery: undefined,
                skip: (paginationState.currentPage - 1) * paginationState.itemsPerPage,
                take: paginationState.itemsPerPage,
            } as ListCompanyUsersRequestDto,
            false,
        )
            .unwrap()
            .then((response) => {
                setCompanyUsers(response.items);
                setPaginationState({
                    currentPage: paginationState.currentPage,
                    itemsPerPage: paginationState.itemsPerPage,
                    totalItems: response.totalCount,
                });
            })
            .catch(() => {
                toast.error(t("companyProfileUsers.api.listCompanyUsersError"));
                setCompanyUsers([]);
            });
    }, [paginationState.currentPage, paginationState.itemsPerPage, t, toast, companyUuid, listCompanyUsers]);

    const openEditUserDrawer = useCallback((user: CompanyUserDto | null) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Users - Edit - ${user?.firstName} ${user?.lastName}`,
        });
        setSelectedUser(user);
        setIsEditDrawerOpen(true);
    }, []);

    const closeEditUserDrawer = useCallback(() => {
        setIsEditDrawerOpen(false);
        setSelectedUser(null);
    }, []);

    const showReinviteUserPopup = (user: CompanyUserDto) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Users - Re-invite - ${user?.firstName} ${user?.lastName}`,
        });
        setSelectedUser(user);
        setIsReinviteUserPopupOpen(true);
    };

    const showDeleteUserPopup = (user: CompanyUserDto) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Users - Delete - ${user?.firstName} ${user?.lastName}`,
        });
        setSelectedUser(user);
        setIsDeleteUserPopupOpen(true);
    };

    const hideReinviteUserPopup = () => {
        setSelectedUser(null);
        setIsReinviteUserPopupOpen(false);
        setIsReinvitingUser(false);
    };

    const hideDeleteUserPopup = () => {
        setSelectedUser(null);
        setIsDeleteUserPopupOpen(false);
    };

    const reinviteUser = () => {
        if (companyUuid && selectedUser !== null) {
            setIsReinvitingUser(true);
            reinviteCompanyUser(companyUuid, selectedUser.userUuid).then(() => {
                toast.success(t("companyProfileUsers.api.reinviteUserSuccess"));
                hideReinviteUserPopup();
            });
        }
    };

    const deleteUser = () => {
        if (companyUuid && selectedUser !== null) {
            deleteCompanyUser({
                companyUuid,
                userUuid: selectedUser.userUuid,
            }).finally(() => {
                fetchUsers();
                setIsDeleteUserPopupOpen(false);
            });
        }
    };

    const processSaveUserResponse = (successMessage: string) => {
        closeEditUserDrawer();
        toast.success(successMessage);
        setPaginationState(defaultPageState);
        fetchUsers();
    };

    const processErrorResponse = (response: AxiosResponse) => {
        if (response.data.type === TransactionErrorException) {
            toast.error(response.data.errors.error[0]);
            return;
        }
        toast.error(t("companyProfileUsers.api.createUserError"));
    };

    const saveUser = (user: CompanyUserDto) => {
        if (companyUuid) {
            if (validationService.isValidUuid(user.userUuid)) {
                if (!user.roleName || (!adminRoles.includes(user.roleName) && noOtherAdmins(user.userUuid))) {
                    toast.error(t("companyProfileUsers.form.roleName.oneAdminRequired"));
                    return;
                }

                updateCompanyUser({ companyUuid, user })
                    .unwrap()
                    .then(() => {
                        processSaveUserResponse(t("companyProfileUsers.api.updateUserSuccess"));
                    })
                    .catch((response) => {
                        processErrorResponse(response);
                    });
            } else {
                createCompanyUser({ companyUuid, user })
                    .unwrap()
                    .then(() => {
                        processSaveUserResponse(t("companyProfileUsers.api.createUserSuccess"));
                    })
                    .catch((response) => {
                        processErrorResponse(response);
                    });
            }
        }
    };

    useEffect(() => {
        dispatch(setPageTitle(t("companyProfileUsers.title")));
        dispatch(
            setBack({
                route: buildRoute(routes.company.companyProfile, { companyUuid }),
            }),
        );
        if (userCanCreateUsers) {
            const addUserButtonProps = {
                onClick: () => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Company - Add User",
                    });
                    openEditUserDrawer(null);
                },
                label: t("companyProfileUsers.addUser"),
                testid: "add-user-button",
                icon: faPlus,
            } as IButtonProps;
            dispatch(setCtas([addUserButtonProps]));
            dispatch(setMobileFooterCtas([addUserButtonProps]));
        }
        return () => {
            dispatch(resetLayout());
        };
    }, [dispatch, t, userCanCreateUsers, openEditUserDrawer, companyUuid]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    if (isLoading || !companyUuid) {
        return <Loader />;
    }

    return (
        <>
            <CompanyUsersDisplay
                users={companyUsers}
                paginationState={paginationState}
                navigateToPage={navigateToPage}
                onEditClick={openEditUserDrawer}
                onDeleteClick={showDeleteUserPopup}
                onReInviteClick={showReinviteUserPopup}
                companyIsBuyer={companyDetails?.allowBuyerUsers ?? false}
                isGlobalAdmin={userIsMaistro}
                canEdit={userCanEditUsers}
            />
            <UserSavingContext.Provider value={isSavingUser || isCreatingUser}>
                <EditUserDrawer
                    isOpen={isEditDrawerOpen}
                    onClose={closeEditUserDrawer}
                    selectedUser={selectedUser}
                    onSubmit={saveUser}
                    companyIsBuyer={companyDetails?.allowBuyerUsers ?? false}
                    companyServiceOrientationTypeId={companyDetails?.serviceOrientationTypeId}
                    isCreateMode={!validationService.isValidUuid(selectedUser?.userUuid)}
                />
            </UserSavingContext.Provider>
            <Popup
                isOpen={isReinviteUserPopupOpen}
                onClose={hideReinviteUserPopup}
                testid="reinvite-user-popup"
                title={t("companyProfileUsers.popups.reinviteUser.title")}
                message={t("companyProfileUsers.popups.reinviteUser.message", {
                    firstName: selectedUser?.firstName,
                    lastName: selectedUser?.lastName,
                })}
                primaryActionText={t("companyProfileUsers.popups.reinviteUser.primaryActionText")}
                secondaryActionText={t("companyProfileUsers.popups.reinviteUser.secondaryActionText")}
                onPrimaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Company Users - Re-invite Submit - ${selectedUser?.firstName} ${selectedUser?.lastName}`,
                    });
                    reinviteUser();
                }}
                onSecondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Company Users - Re-invite Cancel - ${selectedUser?.firstName} ${selectedUser?.lastName}`,
                    });
                    hideReinviteUserPopup();
                }}
                disabled={isReinvitingUser}
            />
            <Popup
                isOpen={isDeleteUserPopupOpen}
                onClose={hideDeleteUserPopup}
                testid="delete-user-popup"
                title={t("companyProfileUsers.popups.deleteUser.title")}
                message={t("companyProfileUsers.popups.deleteUser.message", {
                    firstName: selectedUser?.firstName,
                    lastName: selectedUser?.lastName,
                })}
                primaryActionText={t("companyProfileUsers.popups.deleteUser.primaryActionText")}
                secondaryActionText={t("companyProfileUsers.popups.deleteUser.secondaryActionText")}
                onPrimaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Company Users - Delete Submit - ${selectedUser?.firstName} ${selectedUser?.lastName}`,
                    });
                    deleteUser();
                }}
                onSecondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Company Users - Delete Cancel - ${selectedUser?.firstName} ${selectedUser?.lastName}`,
                    });
                    hideDeleteUserPopup();
                }}
                disabled={isDeletingUser}
            />
            <CompanyProfileActionPopup
                isCompanyProfileActionPopupOpen={isCompanyProfileActionPopupOpen}
                setIsCompanyProfileActionPopupOpen={setIsCompanyProfileActionPopupOpen}
                isActive={companyDetails?.isActive}
                isBuyer={companyDetails?.allowBuyerUsers}
                companyUuid={companyDetails?.uuid}
                registeredName={companyDetails?.registeredName}
            />
        </>
    );
};

export default CompanyUsersContainer;

enum RolesEnum {
    GlobalAdmin = "GlobalAdmin",
    CompanyAdmin = "CompanyAdmin",
    ProjectContributor = "ProjectContributor",
    User = "User",
    BuyerReadOnly = "BuyerReadOnly",
    TechnologyCompanyAdmin = "TechnologyCompanyAdmin",
    TechnologyUser = "TechnologyUser",
    FullServiceCompanyAdmin = "FullServiceCompanyAdmin",
    FullServiceUser = "FullServiceUser",
    ProjectManager = "ProjectManager",
    Supplier = "Supplier",
}

export default RolesEnum;

import { Popup, useToaster } from "@maistro/components";
import { Form, Formik } from "formik";
import { TFunction } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { createCompany } from "api/company/companyApi";
import { Loader } from "components";
import Prompt from "components/Prompt/Prompt";
import { ICategoryProps, IOptionProps } from "components/shared";
import AdditionalInformationForm, {
    additionalInformationSchema,
} from "features/company/profile/edit/AdditionalInformationForm";
import CompanyInformationForm from "features/company/profile/edit/CompanyInformationForm";
import { companyInformationSchema } from "features/company/profile/edit/EditCompanyProfileContainer";
import PrimaryUserForm, { primaryUserSchema } from "features/company/profile/edit/PrimaryUserForm";
import useCompanyProfileForm, {
    useCompanyProfileFormStyles,
} from "features/company/profile/edit/hooks/useCompanyProfileForm";
import ICreateCompanyProfileForm from "features/company/profile/edit/types/ICreateCompanyProfileForm";
import useCompanyServices from "features/company/profile/services/hooks/useCompanyServices";
import useCurrentUser from "hooks/useCurrentUser";
import ReactGA from "react-ga4";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import { CreateCompanyProfileRequestDto } from "types/dtos/company/profile/CreateCompanyProfileRequestDto";
import ServiceOrientationType from "types/enums/companies/ServiceOrientationType";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

export const addressSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        streetAddress: Yup.string().required(t("companyProfile.companyInformation.streetAddress.required")),
        city: Yup.string().required(t("companyProfile.companyInformation.city.required")),
        county: Yup.string().optional(),
        countryTypeId: Yup.string().required(t("companyProfile.companyInformation.country.required")),
        postcode: Yup.string().required(t("companyProfile.companyInformation.postcode.required")),
        companyServices: Yup.array()
            .of(Yup.mixed<ICategoryProps>().nullable())
            .when("$companyType", ([companyType], schema) => {
                return companyType === "supplier" ? schema.min(1, t("companyServices.services.required")) : schema;
            }),
    });

const CreateCompanyProfileContainer: React.FC = () => {
    const classes = useCompanyProfileFormStyles();

    const [isSaving, setIsSaving] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [showConfirmActivatePopup, setShowConfirmActivatePopup] = useState(false);

    const { t } = useTranslation();
    const toast = useToaster();
    const navigate = useNavigate();

    const { userIsMaistro } = useCurrentUser();

    const { isReferenceDataLoading, countryOptions, industryOptions, sizeOptions, setSubmissionDisabled } =
        useCompanyProfileForm(true);

    const { fetchTaxonomyOptions } = useCompanyServices();

    const isLoading = useMemo(() => isReferenceDataLoading, [isReferenceDataLoading]);
    const [formData, setFormData] = useState<ICreateCompanyProfileForm | null>(null);

    useEffect(() => {
        setSubmissionDisabled(isSaving || !isValid);
    }, [isSaving, isValid, setSubmissionDisabled]);

    const serviceOrientationOptions: IOptionProps[] = [
        { label: t("common.standard"), value: "0" },
        { label: t("common.technology"), value: "1" },
        { label: t("common.fullService"), value: "2" },
    ];

    const onSubmit = async () => {
        if (formData !== null) {
            setIsSaving(true);
            const request: CreateCompanyProfileRequestDto = {
                registeredName: formData.registeredName,
                tradingName: formData.tradingName,
                description: formData.description,
                industryTypeId: formData.industryTypeId,
                sizeTypeId: formData.sizeTypeId,
                serviceOrientationTypeId:
                    formData.companyType === "supplier"
                        ? ServiceOrientationType.FullService.toString()
                        : formData.serviceOrientationTypeId,
                contactEmail: formData.contactEmail,
                companiesHouseNumber: formData.companiesHouseNumber,
                registrationNumber: formData.registrationNumber,
                vatRegistrationNumber: formData.vatRegistrationNumber,
                websiteUrl: formData.websiteUrl,
                linkedInUrl: formData.linkedInUrl,
                allowBuyerUsers: formData.companyType === "buyer",
                allowSupplierUsers: formData.companyType === "supplier",
                isMaistroSupplier: formData.companyType === "supplier" ? formData.isMaistroSupplier : false,
                companyServices:
                    formData.companyType === "supplier" ? formData.companyServices?.map((x) => x.value) : [],
                postalAddress: {
                    streetAddress: formData.streetAddress,
                    city: formData.city,
                    county: formData.county,
                    postcode: formData.postcode,
                    countryTypeId: formData.countryTypeId,
                    isPrimaryAddress: true,
                    isInvoicingAddress: true,
                },
                primaryUser: {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    emailAddress: formData.emailAddress,
                },
            };
            const companyResponse = await createCompany(request);

            if (companyResponse.data instanceof TransactionErrorDto || companyResponse.status !== 200) {
                if ("errors" in companyResponse.data) {
                    toast.error(companyResponse.data.errors.error[0]);
                } else {
                    toast.error(t("companyProfile.api.createCompanyError"));
                }
                setIsSaving(false);
                return;
            }

            setFormData(null);
            setShowConfirmActivatePopup(false);
            toast.success(t("companyProfile.api.createCompanySuccess"));
            navigate(buildPath(routes.company.companyProfile, { companyUuid: companyResponse.data.uuid }));
        }
    };

    const confirmActivation = (values: ICreateCompanyProfileForm) => {
        setFormData(values);
        setShowConfirmActivatePopup(true);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <Formik
                initialValues={{
                    registeredName: "",
                    streetAddress: "",
                    city: "",
                    county: "",
                    countryTypeId: "",
                    postcode: "",
                    sizeTypeId: "0",
                    companyType: "",
                    serviceOrientationTypeId: "0",
                    isMaistroSupplier: true,
                    companiesHouseNumber: "",
                    registrationNumber: "",
                    description: "",
                    firstName: "",
                    lastName: "",
                    emailAddress: "",
                    tradingName: "",
                    industryTypeId: "0",
                    vatRegistrationNumber: "",
                    contactEmail: "",
                    websiteUrl: "",
                    linkedInUrl: "",
                    companyServices: undefined,
                }}
                onSubmit={(values) => confirmActivation(values)}
                validationSchema={companyInformationSchema(t)
                    .concat(addressSchema(t))
                    .concat(primaryUserSchema(t))
                    .concat(additionalInformationSchema(t))}
                validateOnMount
            >
                {({ dirty }) => {
                    return (
                        <>
                            <Form className={classes.form} id="company-profile-form">
                                <CompanyInformationForm
                                    userIsMaistro={userIsMaistro}
                                    countries={countryOptions}
                                    sizes={sizeOptions}
                                    serviceOrientations={serviceOrientationOptions}
                                    setIsValid={setIsValid}
                                    isCreateMode
                                    fetchTaxonomyOptions={fetchTaxonomyOptions}
                                />
                                <hr />
                                <PrimaryUserForm />
                                <hr />
                                <AdditionalInformationForm industries={industryOptions} />
                            </Form>
                            <Prompt when={dirty && !isSaving} />
                        </>
                    );
                }}
            </Formik>
            <Popup
                isOpen={showConfirmActivatePopup}
                onClose={() => {
                    setFormData(null);
                    setShowConfirmActivatePopup(false);
                }}
                testid="unsaved-changes-warning-popup"
                title={t("companyProfile.popups.confirmActivate.title")}
                message={t("companyProfile.popups.confirmActivate.message")}
                primaryActionText={t("companyProfile.popups.confirmActivate.primaryActionText")}
                secondaryActionText={t("companyProfile.popups.confirmActivate.secondaryActionText")}
                onPrimaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Company Create - Activate Profile - Submit - ".concat(
                            formData?.registeredName || "No Name",
                        ),
                    });
                    onSubmit();
                }}
                onSecondaryAction={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Company Create - Activate Profile - Cancel",
                    });
                    setFormData(null);
                    setShowConfirmActivatePopup(false);
                }}
                disabled={isSaving}
            />
        </>
    );
};

export default CreateCompanyProfileContainer;

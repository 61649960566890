import {
    AutosaveForm,
    Button,
    CategoriesField,
    CheckboxField,
    RadioButtonsField,
    RichTextEditorField,
    SelectField,
    TextButton,
    TextField,
} from "@maistro/components";
import { Formik } from "formik";
import { TFunction } from "i18next";
import { isEmpty } from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { ICategoryProps, IOptionProps } from "components/shared";
import DisplayFileUploadField from "features/files/DisplayFileUploadFields";
import ProjectContributorsField from "features/project/projectBrief/components/ProjectContributorsField";
import ProjectDescriptionField from "features/project/projectBrief/components/ProjectDescriptionField";
import PublishToPortal from "features/project/projectBrief/components/PublishToPortal";
import BudgetTypeSelectorLogic from "features/project/projectBrief/form-logic/BudgetTypeSelectorLogic";
import { IProjectInformation } from "features/project/types";
import useReduxSelector from "hooks/useReduxSelector";
import ReactGA from "react-ga4";
import currencyService from "services/currencyService";
import { ITheme } from "styles/themes/types";
import { DirtyValues, constructDirtyValues } from "types/dtos/DirtyValues";
import { CompanyUserDto } from "types/dtos/company/CompanyUserDto";
import FileDto from "types/dtos/files/FileDto";
import CategorySelectionStatus from "types/enums/CategorySelectionStatus";
import CurrencyType from "types/enums/CurrencyType";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import BudgetType from "types/enums/projects/BudgetType";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import ProjectType from "types/enums/projects/ProjectType";

const useStyles = createUseStyles((theme: ITheme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
    buttonControl: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing.large,
    },
    budget: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
    },
    budgetInput: {},
    button: {
        marginBottom: theme.spacing.small,
    },
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        budget: {
            flexDirection: "row",
            alignItems: "end",
            gap: theme.spacing.medium,
        },
        budgetInput: {
            minWidth: "280px",
        },
    },
}));

interface IProjectBriefFormProps {
    values: IProjectInformation;
    fetchCategoryOptions: (searchParameter: string) => Promise<ICategoryProps[]>;
    save: (values: IProjectInformation, dirtyValues: DirtyValues, autoSave?: boolean) => Promise<void>;
    cancel: (companyUuid?: string) => void;
    disableSubmit: boolean;
    processText: (
        inputText: string,
        setFieldValue: (field: string, value: ICategoryProps[], shouldValidate?: boolean) => void,
        categories: Array<ICategoryProps>,
    ) => void;
    showClientOptions: boolean;
    clientOptions: IOptionProps[];
    fetchClientOptions: (searchClientText: string) => Promise<void>;
    onClientSelect: (client?: string) => Promise<void>;
    sponsorOptions: IOptionProps[];
    companyUsers: CompanyUserDto[];
    files?: FileDto[];
    setFiles: React.Dispatch<React.SetStateAction<FileDto[]>>;
    removeFile: (file: FileDto | null) => void;
    newProjectUuid: string | null;
    handleSponsorSearchTextChange: (searchText: string) => void;
    handleContributorSearchTextChange: (searchText: string) => void;
}

const baseSchema = (t: TFunction<"translation", undefined>) =>
    Yup.object({
        projectName: Yup.string().required(t("projectBrief.name.required")),
        manager: Yup.string().required(t("projectBrief.manager.required")),
        description: Yup.string().required(t("projectBrief.description.required")),
        categories: Yup.array()
            .of(
                Yup.object().shape({
                    value: Yup.string(),
                    label: Yup.string(),
                    status: Yup.number(),
                }),
            )
            .compact((c) => c.status === CategorySelectionStatus.Removed)
            .min(1, t("projectBrief.categories.required")),
        budgetCurrency: Yup.string().required(t("projectBrief.currency.required")),
        sponsorUserUuid: Yup.string().nullable().required(t("projectBrief.sponsor.required")),
    });

const standardValidationSchema = (t: TFunction<"translation", undefined>) =>
    baseSchema(t).concat(
        Yup.object({
            buyerBudget: Yup.number().when("budgetType", {
                is: (value: BudgetType) => {
                    return value === BudgetType.Budget;
                },
                then: (schema) =>
                    schema.required(t("projectBrief.buyerBudget.required")).test("min", (value, ctx) => {
                        if (value !== undefined && value < 1) {
                            return ctx.createError({
                                message: t("projectBrief.buyerBudget.minValidation", {
                                    currencySymbol: currencyService.toCurrencyIcon(ctx.parent.budgetCurrency),
                                }),
                            });
                        }
                        return true;
                    }),
            }),
        }),
    );

const globalAdminValidationSchema = (t: TFunction<"translation", undefined>) =>
    standardValidationSchema(t).concat(
        Yup.object({
            clientCompanyUuid: Yup.string().nullable().required(t("projectBrief.client.required")),
        }),
    );

const eoiValidationSchema = (t: TFunction<"translation", undefined>) =>
    baseSchema(t).concat(
        Yup.object({
            expressionOfInterestDeadline: Yup.date()
                .nullable()
                .required(t("projectBrief.expressionOfInterest.required"))
                .min(new Date(), t("projectBrief.expressionOfInterest.minError")),
            buyerBudget: Yup.number()
                .required(t("projectBrief.buyerBudget.eoiRequired"))
                .test("min", (value, ctx) => {
                    if (value !== undefined && value < 0) {
                        return ctx.createError({
                            message: t("projectBrief.buyerBudget.eoiMinValidation", {
                                currencySymbol: currencyService.toCurrencyIcon(ctx.parent.budgetCurrency),
                            }),
                        });
                    }
                    return true;
                }),
        }),
    );

const ProjectBriefForm: React.FC<IProjectBriefFormProps> = (props) => {
    const classes = useStyles();

    const { theme } = useReduxSelector((state) => state.themeState);

    const [fileIsUploading, setFileIsUploading] = useState(false);

    const { t } = useTranslation();

    const canEditBrief = useMemo(() => {
        return (
            props.values &&
            (!props.values.status ||
                [ProjectStatus.Create, ProjectStatus.Invite].includes(props.values.status as ProjectStatus))
        );
    }, [props.values]);

    const budgetTypeOptions = Object.keys(BudgetType)
        .filter((key) => key !== BudgetType.Budget)
        .map((key) => ({
            value: key,
            label: t(`budgetTypes.names.${key}`),
        }));

    const disableSubmission = useMemo(
        () => props.disableSubmit || !canEditBrief || fileIsUploading,
        [canEditBrief, fileIsUploading, props.disableSubmit],
    );

    const projectType = props.values.type;
    const isEoiProject = projectType === ProjectType.ExpressionOfInterest;

    const setValidationSchema = () => {
        if (isEoiProject) {
            return eoiValidationSchema(t);
        }
        return props.showClientOptions ? globalAdminValidationSchema(t) : standardValidationSchema(t);
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                uuid: props.values.uuid,
                companyUuid: props.values.companyUuid,
                projectName: props.values.projectName,
                companyName: props.values.companyName,
                manager: props.values.manager,
                managerUuid: props.values.managerUuid,
                description: props.values.description,
                timescale: props.values.timescale,
                categories: props.values.categories,
                budgetCurrency: props.values.budgetCurrency,
                buyerBudget: props.values.buyerBudget,
                showBudgetToSupplier: props.values.showBudgetToSupplier,
                budgetType: props.values.budgetType,
                status: props.values.status,
                decisionSummary: props.values.decisionSummary,
                clientCompanyUuid: props.values.clientCompanyUuid,
                sponsorUserUuid: props.values.sponsorUserUuid,
                contributors: props.values.contributors,
                expressionOfInterestDeadline: props.values.expressionOfInterestDeadline,
            }}
            validationSchema={setValidationSchema()}
            onSubmit={(values) =>
                props.save(
                    values,
                    constructDirtyValues(values, () => true),
                )
            }
        >
            {({ values, errors, setFieldValue }) => (
                <AutosaveForm<typeof values>
                    className={classes.form}
                    when={
                        !disableSubmission &&
                        values.projectName.length > 0 &&
                        !(props.showClientOptions && !values.clientCompanyUuid) &&
                        values.manager.length > 0
                    }
                    onSave={(dirtyValues) => props.save(values, dirtyValues, true)}
                    successMessage={t("components.autosave.success")}
                    errorMessage={t("components.autosave.error")}
                >
                    <TextField
                        testid="project-brief-name"
                        label={t("projectBrief.name.label")}
                        name="projectName"
                        required
                        tooltip={t("projectBrief.name.tooltip")}
                    />
                    {props.showClientOptions && (
                        <SelectField
                            name="clientCompanyUuid"
                            label={t("projectBrief.client.label")}
                            required
                            tooltip={t("projectBrief.client.tooltip")}
                            options={props.clientOptions}
                            onChange={(value) => {
                                props.onClientSelect(value);
                                setFieldValue("sponsorUserUuid", "");
                                setFieldValue("contributors", []);
                            }}
                            onInputChange={(value) => props.fetchClientOptions(value)}
                            disabled={!!props.values.clientCompanyUuid && props.values.status !== ProjectStatus.Create}
                            clearable
                            testid="project-brief-client-select"
                        />
                    )}
                    <SelectField
                        name="sponsorUserUuid"
                        label={t("projectBrief.sponsor.label")}
                        required
                        tooltip={t("projectBrief.sponsor.tooltip")}
                        options={props.sponsorOptions}
                        onInputChange={(value) => props.handleSponsorSearchTextChange(value)}
                        testid="project-brief-sponsor"
                    />
                    <TextField
                        testid="project-brief-manager"
                        label={t("projectBrief.manager.label")}
                        name="manager"
                        required
                        disabled
                        tooltip={t("projectBrief.manager.tooltip")}
                    />
                    {projectType === ProjectType.Tender && (
                        <ProjectContributorsField
                            companyUsers={props.companyUsers}
                            handleContributorSearchTextChange={props.handleContributorSearchTextChange}
                        />
                    )}
                    <ProjectDescriptionField
                        disableSubmission={disableSubmission}
                        buttonOnly={props.values.description.length > 0}
                        projectUuid={props.values.uuid}
                        newProjectUuid={props.newProjectUuid}
                        processText={props.processText}
                        existingImages={props.values.imagesNames}
                    />
                    {projectType !== undefined &&
                        [ProjectType.Tender, ProjectType.Quote, ProjectType.TechnologyQuote].includes(projectType) && (
                            <DisplayFileUploadField
                                testid="project-brief"
                                files={
                                    props.files
                                        ? props.files.filter(
                                              (file) =>
                                                  file.linkedCompanyUuid === values.clientCompanyUuid ||
                                                  file.newUpload ||
                                                  file.linkedCompanyUuid == null,
                                          )
                                        : undefined
                                }
                                canManageFiles={canEditBrief}
                                removeFile={props.removeFile}
                                setFiles={props.setFiles}
                                fileIsUploading={fileIsUploading}
                                setFileIsUploading={setFileIsUploading}
                            />
                        )}
                    {(projectType === ProjectType.Quote ||
                        projectType === ProjectType.TechnologyQuote ||
                        isEoiProject) && (
                        <RichTextEditorField
                            testid="project-brief-timescale"
                            label={t("projectBrief.timescale.label")}
                            name="timescale"
                            tooltip={t("projectBrief.timescale.tooltip")}
                            showTable
                            height={200}
                            joditLicense={theme.joditLicence}
                        />
                    )}
                    <CategoriesField
                        name="categories"
                        label={t("projectBrief.categories.label")}
                        tooltip={t("projectBrief.categories.tooltip")}
                        onSearch={props.fetchCategoryOptions}
                        testid="project-brief-categories"
                    />
                    <SelectField
                        testid="project-brief-currency"
                        label={t("projectBrief.currency.label")}
                        name="budgetCurrency"
                        options={[
                            {
                                value: CurrencyType.GBP.toString(),
                                label: "£ (GBP)",
                            },
                            {
                                value: CurrencyType.EUR.toString(),
                                label: "€ (EUR)",
                            },
                            {
                                value: CurrencyType.USD.toString(),
                                label: "$ (USD)",
                            },
                        ]}
                        required
                        tooltip={t("projectBrief.currency.tooltip")}
                    />
                    <div className={classes.budget}>
                        <div className={classes.budgetInput}>
                            <TextField
                                testid="buyer-budget"
                                label={t("projectBrief.buyerBudget.label")}
                                tooltip={
                                    isEoiProject
                                        ? t("projectBrief.buyerBudget.eoiTooltip", {
                                              currencySymbol: currencyService.toCurrencyIcon(values.budgetCurrency),
                                          })
                                        : t("projectBrief.buyerBudget.tooltip")
                                }
                                name="buyerBudget"
                                type="price"
                                currencyType={values.budgetCurrency}
                                required={values.budgetType === BudgetType.Budget}
                            />
                        </div>
                        <CheckboxField
                            testid="show-budget-to-supplier"
                            name="showBudgetToSupplier"
                            label={t("projectBrief.buyerBudget.showBudgetToSupplier")}
                        />
                    </div>
                    {!isEoiProject && (
                        <>
                            <RadioButtonsField
                                name="budgetType"
                                options={budgetTypeOptions}
                                columns={0}
                                testid="budget-type"
                            />
                            <BudgetTypeSelectorLogic />
                        </>
                    )}
                    {isEoiProject && (
                        <PublishToPortal
                            uuidValue={values.uuid}
                            expressionOfInterestDeadline={values.expressionOfInterestDeadline}
                        />
                    )}
                    <div className={classes.buttonControl}>
                        <Button
                            label={isEoiProject ? t("common.continue") : t("projectBrief.submit")}
                            size="large"
                            type="submit"
                            chevron
                            disabled={
                                !isEmpty(errors) ||
                                disableSubmission ||
                                (!props.newProjectUuid && values.uuid === "new")
                            }
                            testid="project-brief-submit-button"
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Find Suppliers - ".concat(values.projectName),
                                });
                            }}
                        />
                        <TextButton
                            label={t("projectBrief.cancel")}
                            onClick={() => {
                                ReactGA.event({
                                    category: ga4Category.Button,
                                    action: ga4Action.ButtonClick,
                                    label: "Create Project Cancel",
                                });
                                props.cancel(values.clientCompanyUuid);
                            }}
                            testid="project-brief-cancel-button"
                        />
                    </div>
                </AutosaveForm>
            )}
        </Formik>
    );
};

export default ProjectBriefForm;

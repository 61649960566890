import { Popup, useToaster } from "@maistro/components";
import { IButtonProps } from "@maistro/components/dist/esm/types/components/Button/Button";
import { createPolicy, deletePolicy, listPoliciesForGroup, updatePolicy } from "api/company/policyApi";
import { createUserGroup, getUserGroup, updateUserGroup } from "api/company/userGroupApi";
import { Loader } from "components";
import Prompt from "components/Prompt/Prompt";
import { IOptionProps } from "components/shared";
import CreateUserGroupDisplay from "features/company/buyer/userGroups/CreateUserGroupDisplay";
import PolicyDrawer from "features/company/buyer/userGroups/PolicyDrawer";
import useAppDispatch from "hooks/useAppDispatch";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import validationService from "services/validationService";
import { useLazyListCompanyUsersQuery } from "store/api/companyUsersApi";
import { resetLayout, setBack, setCtas, setMobileFooterCtas, setPageTitle } from "store/layoutSlice";
import { PolicyDto } from "types/dtos/company/policies/PolicyDto";
import { UserGroupDto } from "types/dtos/company/userGroups/UserGroupDto";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import PolicyType from "types/enums/companies/Approvals/PolicyType";
import SupplierTieringStatus from "types/enums/companies/SupplierTieringStatus";
import ProjectType from "types/enums/projects/ProjectType";

interface ICreateUserGroupParams {
    companyUuid: string;
    groupUuid: string;
}

const CreateUserGroupContainer: React.FC = () => {
    const [userGroup, setUserGroup] = useState<UserGroupDto>();
    const [policies, setPolicies] = useState<PolicyDto[]>([]);
    const [companyUsers, setCompanyUsers] = useState<IOptionProps[]>([]);
    const [isSaving, setIsSaving] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState<PolicyDto>();

    const params = useParams();
    const { companyUuid, groupUuid } = params as unknown as ICreateUserGroupParams;

    const [isPolicyDrawerOpen, setIsPolicyDrawerOpen] = useState(false);
    const [isCancelPolicyPopupOpen, setIsCancelPolicyPopupOpen] = useState(false);
    const [isDeletePolicyPopupOpen, setIsDeletePolicyPopupOpen] = useState(false);
    const [isEditPolicyPopupOpen, setIsEditPolicyPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [createdGroupUuid, setCreatedGroupUuid] = useState(groupUuid);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const toast = useToaster();
    const [listCompanyUsers] = useLazyListCompanyUsersQuery();

    const createOrUpdateGroup = useCallback(
        async (values: UserGroupDto) => {
            if (!values.id || values.id === "new") {
                return createUserGroup(companyUuid, { name: values.name, memberUuids: values.memberUuids });
            }

            return updateUserGroup(companyUuid, values);
        },
        [companyUuid],
    );

    const saveTieringPolicy = useCallback(
        async (userGroupUuid: string, supplierTierings: SupplierTieringStatus[]) => {
            const hasTieringPolicy = supplierTierings.length > 0;
            const existingPolicy = policies.find((policy) => policy.type === PolicyType.Tiering);

            if (existingPolicy !== undefined && !hasTieringPolicy) {
                return deletePolicy(companyUuid, userGroupUuid, existingPolicy.id);
            }

            if (
                existingPolicy !== undefined &&
                hasTieringPolicy &&
                existingPolicy.supplierTierings !== supplierTierings
            ) {
                return updatePolicy({
                    ...existingPolicy,
                    companyUuid,
                    supplierTierings,
                });
            }

            if (existingPolicy === undefined && hasTieringPolicy) {
                return createPolicy({
                    companyUuid,
                    groupUuid: userGroupUuid,
                    name: "Supplier Tiering",
                    type: PolicyType.Tiering,
                    customMessage: "",
                    softApproval: true,
                    projectTypes: [ProjectType.Tender, ProjectType.Quote, ProjectType.TechnologyQuote],
                    approverUuids: [],
                    supplierTierings,
                });
            }

            return Promise.resolve();
        },
        [companyUuid, policies],
    );

    const saveGroup = useCallback(
        async (
            values: UserGroupDto,
            supplierTierings: SupplierTieringStatus[],
            setFieldError: (field: string, message: string) => void,
        ) => {
            setIsSaving(true);
            return createOrUpdateGroup({ ...values, id: createdGroupUuid }).then((userGroupResponse) => {
                if (userGroupResponse.data instanceof TransactionErrorDto) {
                    if (userGroupResponse.data.errors.error.some((x: string) => x === "Group name already exists")) {
                        setFieldError("name", t("userGroups.api.userGroupNameDuplicate"));
                    }
                    setIsSaving(false);
                    return Promise.reject(new Error(t("userGroups.api.userGroupNameDuplicate")));
                }

                if (userGroupResponse.status !== 200) {
                    setIsSaving(false);
                    toast.error(t("userGroups.api.saveUserGroupError"));
                    return Promise.reject(new Error(t("userGroups.api.saveUserGroupError")));
                }

                setUserGroup(userGroupResponse.data);
                setCreatedGroupUuid(userGroupResponse.data.id);

                return saveTieringPolicy(userGroupResponse.data.id, supplierTierings).then((policyResponse) => {
                    if (policyResponse && policyResponse.status !== 200 && policyResponse.status !== 204) {
                        toast.error(t("userGroups.api.tieringPolicyError"));
                        setIsSaving(false);
                        return Promise.reject(new Error(t("userGroups.api.tieringPolicyError")));
                    }

                    toast.success(t("userGroups.api.saveUserGroupSuccess"));
                    setIsSaving(false);
                    return Promise.resolve();
                });
            });
        },
        [createOrUpdateGroup, saveTieringPolicy, t, toast, createdGroupUuid],
    );

    useEffect(() => {
        dispatch(setPageTitle(t("userGroups.create.title")));
        dispatch(
            setBack({
                route: buildRoute(routes.company.userGroups, { companyUuid }),
            }),
        );

        const saveGroupButtonProps: IButtonProps = {
            label: t("userGroups.create.saveGroup"),
            testid: "save-group-button",
            type: "submit",
            form: "user-group-form",
            disabled: isSaving,
        };
        dispatch(setCtas([saveGroupButtonProps]));
        dispatch(setMobileFooterCtas([saveGroupButtonProps]));

        return () => {
            dispatch(resetLayout());
        };
    }, [companyUuid, dispatch, isSaving, saveGroup, t]);

    const fetchCompanyUsers = useCallback(async () => {
        listCompanyUsers({ companyUuid, includeUserRoles: true })
            .unwrap()
            .then((response) => {
                const users = response.items.map((user) => {
                    const roleLabel = t(`roles.names.${user.roleName}`);
                    return {
                        value: user.userUuid,
                        label: `${user.firstName} ${user.lastName} - ${roleLabel}`,
                    };
                });
                setCompanyUsers(users);
            })
            .catch(() => {
                setCompanyUsers([]);
                toast.error(t("userGroups.api.fetchCompanyUsersError"));
            });
    }, [companyUuid, t, toast, listCompanyUsers]);

    const fetchPolicies = useCallback(
        async (currentGroupUuid?: string) => {
            const response = await listPoliciesForGroup(companyUuid, currentGroupUuid ?? createdGroupUuid);
            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                toast.error(t("userGroups.api.fetchPoliciesError"));
                setPolicies([]);
            } else {
                setPolicies(response.data.policies);
            }
        },
        [companyUuid, createdGroupUuid, t, toast],
    );

    const fetchUserGroupDetails = useCallback(async () => {
        const response = await getUserGroup(companyUuid, createdGroupUuid);
        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("userGroups.api.fetchUserGroupError"));
            setUserGroup(undefined);
        } else {
            setUserGroup(response.data);
            await fetchPolicies();
        }
    }, [companyUuid, fetchPolicies, createdGroupUuid, t, toast]);

    useEffect(() => {
        const fetchData = async () => {
            if (!validationService.isValidUuid(companyUuid)) {
                return;
            }
            await fetchCompanyUsers();

            if (createdGroupUuid === "new" || !validationService.isValidUuid(createdGroupUuid)) {
                return;
            }
            await fetchUserGroupDetails();
        };

        fetchData().finally(() => setIsLoading(false));
    }, [companyUuid, fetchCompanyUsers, fetchUserGroupDetails, createdGroupUuid]);

    const createOrUpdatePolicy = useCallback(
        async (values: PolicyDto) => {
            if (selectedPolicy === undefined) {
                return createPolicy({ ...values, companyUuid });
            }

            return updatePolicy({ ...values, companyUuid });
        },
        [companyUuid, selectedPolicy],
    );

    const savePolicy = useCallback(
        async (values: PolicyDto) => {
            return createOrUpdatePolicy(values).then((response) => {
                if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                    toast.error(t("userGroups.api.savePolicyError"));
                    return;
                }

                toast.success(t("userGroups.api.savePolicySuccess"));
                fetchPolicies(response.data.groupUuid);
                setSelectedPolicy(undefined);
                setIsPolicyDrawerOpen(false);
            });
        },
        [createOrUpdatePolicy, fetchPolicies, t, toast],
    );

    const closeCancelPopup = () => {
        setIsCancelPolicyPopupOpen(false);
    };

    const closeDeletePopup = () => {
        setIsDeletePolicyPopupOpen(false);
    };

    const closeEditPopup = () => {
        setIsEditPolicyPopupOpen(false);
        setSelectedPolicy(undefined);
    };

    const confirmDeletePolicy = useCallback(async () => {
        if (selectedPolicy === undefined) return;

        deletePolicy(companyUuid, createdGroupUuid, selectedPolicy.id).then((response) => {
            if (response.data instanceof TransactionErrorDto || response.status !== 204) {
                toast.error(t("userGroups.api.deletePolicyError"));
                return;
            }

            toast.success(t("userGroups.api.deletePolicySuccess"));
            fetchPolicies();
            closeDeletePopup();
        });
        setIsPolicyDrawerOpen(false);
    }, [companyUuid, fetchPolicies, createdGroupUuid, selectedPolicy, t, toast]);

    const confirmEditPolicy = () => {
        setIsEditPolicyPopupOpen(false);
        setIsPolicyDrawerOpen(true);
    };

    const cancelPolicy = () => {
        setIsCancelPolicyPopupOpen(false);
        setIsPolicyDrawerOpen(false);
        setSelectedPolicy(undefined);
    };

    const handleAddPolicy = () => {
        setSelectedPolicy(undefined);
        setIsPolicyDrawerOpen(true);
    };

    const handleDeletePolicy = (policy: PolicyDto) => {
        setSelectedPolicy(policy);
        setIsDeletePolicyPopupOpen(true);
    };

    const handleEditPolicy = (policy: PolicyDto) => {
        setSelectedPolicy(policy);
        setIsEditPolicyPopupOpen(true);
    };

    const onPolicyDrawerClose = (dirty: boolean) => {
        if (dirty) {
            setIsCancelPolicyPopupOpen(true);
            return;
        }
        setIsPolicyDrawerOpen(false);
        setSelectedPolicy(undefined);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <Prompt when={isDirty} />
            <CreateUserGroupDisplay
                companyUuid={companyUuid}
                userGroup={userGroup}
                policies={policies}
                companyUsers={companyUsers}
                saveGroup={saveGroup}
                onAddPolicy={handleAddPolicy}
                onDeletePolicy={handleDeletePolicy}
                onEditPolicy={handleEditPolicy}
                setIsDirty={setIsDirty}
            />
            <PolicyDrawer
                groupUuid={userGroup?.id ?? groupUuid}
                policy={selectedPolicy}
                companyUsers={companyUsers}
                isOpen={isPolicyDrawerOpen}
                onSubmit={savePolicy}
                onDelete={() => setIsDeletePolicyPopupOpen(true)}
                onClose={(dirty) => onPolicyDrawerClose(dirty)}
            />
            <Popup
                title={t("common.prompt.title")}
                message={t("common.prompt.message")}
                primaryActionText={t("common.prompt.primary")}
                secondaryActionText={t("common.prompt.secondary")}
                isOpen={isCancelPolicyPopupOpen}
                onPrimaryAction={cancelPolicy}
                onSecondaryAction={closeCancelPopup}
                onClose={closeCancelPopup}
                testid="close-policy-popup"
            />
            <Popup
                title={t("popups.policies.delete.title")}
                message={t("popups.policies.delete.message", { projectCount: userGroup?.associatedProjectCount })}
                isOpen={isDeletePolicyPopupOpen}
                primaryActionText={t("popups.policies.delete.cta.primary")}
                onPrimaryAction={confirmDeletePolicy}
                secondaryActionText={t("popups.policies.delete.cta.secondary")}
                onSecondaryAction={closeDeletePopup}
                onClose={closeDeletePopup}
                testid="delete-policy-popup"
            />
            <Popup
                title={t("popups.policies.edit.title")}
                message={t("popups.policies.edit.message", { projectCount: userGroup?.associatedProjectCount })}
                isOpen={isEditPolicyPopupOpen}
                primaryActionText={t("popups.policies.edit.cta.primary")}
                onPrimaryAction={confirmEditPolicy}
                secondaryActionText={t("popups.policies.edit.cta.secondary")}
                onSecondaryAction={closeEditPopup}
                onClose={closeEditPopup}
                testid="edit-policy-popup"
            />
        </>
    );
};

export default CreateUserGroupContainer;

enum PermissionsEnum {
    CanBuy = "canBuy",
    CanSupply = "canSupply",

    CreateProject = "createProject",
    CreateDemoProject = "createDemoProject",
    CreatePublicSectorProject = "createPublicSectorProject",

    ReadProject = "readProject",
    UpdateProject = "updateProject",
    DeleteProject = "deleteProject",
    ArchiveProject = "archiveProject",
    DuplicateProject = "duplicateProject",

    CreateTechProject = "createTechProject",
    ReadTechProject = "readTechProject",
    UpdateTechProject = "updateTechProject",
    DeleteTechProject = "deleteTechProject",
    ArchiveTechProject = "archiveTechProject",
    DuplicateTechProject = "duplicateTechProject",

    CreateScoringCriteria = "createScoringCriteria",
    ReadScoringCriteria = "readScoringCriteria",
    UpdateScoringCriteria = "updateScoringCriteria",
    DeleteScoringCriteria = "deleteScoringCriteria",

    CreateScoreTender = "createScoreTender",
    ReadScoreTender = "readScoreTender",
    UpdateScoreTender = "updateScoreTender",
    DeleteScoreTender = "deleteScoreTender",

    CreateScore = "createScore",
    OverrideScore = "overrideScore",

    CreateUser = "createUser",
    ReadUser = "readUser",
    UpdateUser = "updateUser",
    DeleteUser = "deleteUser",

    ReadProjectResponse = "readProjectResponse",
    UpdateProjectResponse = "updateProjectResponse",

    ManageSupport = "manageSupport",

    CreateCompanyProfile = "createCompanyProfile",
    ReadCompanyProfile = "readCompanyProfile",
    UpdateCompanyProfile = "updateCompanyProfile",
    DeleteCompanyProfile = "deleteCompanyProfile",

    ReadCompany = "readCompany",
    UpdateCompany = "updateCompany",

    ReadSupplierRelationshipStatus = "readSupplierRelationshipStatus",
    UpdateSupplierRelationshipStatus = "updateSupplierRelationshipStatus",

    ReadEmail = "readEmail",

    ReadReport = "readReport",

    ReadNotification = "readNotification",

    CanAskClarificationQuestion = "canAskClarificationQuestion",
    CanAnswerClarificationQuestion = "canAnswerClarificationQuestion",

    HasBetaAccess = "hasBetaAccess",

    CanReadUserGroup = "canReadUserGroup",
}

export default PermissionsEnum;

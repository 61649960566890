import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { useToaster } from "@maistro/components";
import { IButtonProps } from "@maistro/components/dist/esm/types/components/Button/Button";
import { getTaxonomyItemsForCompany, setTaxonomyItemsForCompany } from "api/taxonomyApi";
import { Loader } from "components";
import Prompt from "components/Prompt/Prompt";
import { ICategoryProps } from "components/shared";
import useCompanyStepperNavigation from "features/company/profile/hooks/useCompanyStepperNavigation";
import CompanyServicesDisplay from "features/company/profile/services/CompanyServicesDisplay";
import useCompanyServices from "features/company/profile/services/hooks/useCompanyServices";
import useAppDispatch from "hooks/useAppDispatch";
import ReactGA from "react-ga4";
import { buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { useGetCompanyDetailsQuery } from "store/api/companyApi";
import { resetLayout, setBack, setCtas, setMobileFooterCtas, setPageTitle } from "store/layoutSlice";
import TransactionErrorDto from "types/dtos/TransactionErrorDto";
import ServiceOrientationType from "types/enums/companies/ServiceOrientationType";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

interface ICompanyServicesDisplayProps {
    companyServices: ICategoryProps[];
}

const CompanyServicesContainer: React.FC = () => {
    const { t } = useTranslation();
    const toast = useToaster();

    const dispatch = useAppDispatch();
    const { companyUuid } = useParams();
    const { data: companyDetails, isLoading } = useGetCompanyDetailsQuery(companyUuid ?? "");

    const [companyServices, setCompanyServices] = useState<ICategoryProps[]>([]);
    const [isSaving, setIsSaving] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        dispatch(setPageTitle(t("companyServices.title")));
        dispatch(
            setBack({
                route: buildRoute(routes.company.companyProfile, { companyUuid }),
            }),
        );

        const saveServices: IButtonProps = {
            label: t("companyServices.saveServices"),
            testid: "save-services-button",
            type: "submit",
            form: "company-services-form",
            disabled: isSaving || !isDirty || !isValid,
        };
        dispatch(setCtas([saveServices]));
        dispatch(setMobileFooterCtas([saveServices]));

        return () => {
            dispatch(resetLayout());
        };
    }, [companyUuid, dispatch, isDirty, isSaving, isValid, t]);

    useEffect(() => {
        const getServicesForCompany = async () => {
            const response = await getTaxonomyItemsForCompany(companyUuid ?? "");
            if (response.data instanceof TransactionErrorDto || response.status !== 200) {
                toast.error(t("companyServices.api.getTaxonomyError"));
                return;
            }
            const services = response.data._embedded.items.map((x) => {
                return {
                    value: x._embedded.uuid,
                    label: x._embedded.hierarchyDisplayName,
                    status: x.selectionStatusId,
                };
            });
            setCompanyServices(services);
        };

        if (companyUuid !== "") {
            getServicesForCompany();
        }
    }, [companyUuid, t, toast]);

    useCompanyStepperNavigation({
        activeStep: "services",
        title: companyDetails?.registeredName,
        subTitle: companyDetails?.netPromoterScore
            ? t("companyProfile.stepperNavigation.netPromoterScore", {
                  netPromoterScore: companyDetails?.netPromoterScore,
              })
            : "",
        companyUuid: companyUuid ?? "",
        isActive: companyDetails?.isActive,
    });

    const onSubmit = async (values: ICompanyServicesDisplayProps) => {
        ReactGA.event({
            category: ga4Category.Button,
            action: ga4Action.ButtonClick,
            label: `Company Services - Save Services`,
        });
        setIsSaving(true);
        values.companyServices.forEach((element) => {
            if (!companyServices.some((service) => service.label === element.label)) {
                ReactGA.event({
                    category: ga4Category.Element,
                    action: ga4Action.Element,
                    label: `Company Services - Added service - `.concat(element.label),
                });
            }
        });
        companyServices.forEach((element) => {
            if (!values.companyServices.some((service) => service.label === element.label)) {
                ReactGA.event({
                    category: ga4Category.Element,
                    action: ga4Action.Element,
                    label: `Company Services - Removed service -`.concat(element.label),
                });
            }
        });

        const response = await setTaxonomyItemsForCompany(companyUuid ?? "", {
            TaxonomyItems: values.companyServices.map((x) => x.value),
        });
        if (response.data instanceof TransactionErrorDto || response.status !== 200) {
            toast.error(t("companyProfile.api.saveServicesError"));
            setIsSaving(false);
            return;
        }
        setCompanyServices(values.companyServices);
        toast.success(t("companyServices.api.saved"));
        setIsSaving(false);
    };

    const companyInformationSchema = () =>
        Yup.object({
            companyServices: Yup.array()
                .of(Yup.mixed<ICategoryProps>())
                .required(t("companyServices.services.required"))
                .min(1, t("companyServices.services.required")),
        });

    const { fetchTaxonomyOptions } = useCompanyServices();

    const orientationType = useMemo(() => {
        const serviceOrientationTypeId = companyDetails?.serviceOrientationTypeId;

        if (serviceOrientationTypeId === ServiceOrientationType.Standard) {
            return "Standard";
        }

        if (serviceOrientationTypeId === ServiceOrientationType.Technology) {
            return "Technology";
        }

        return "All";
    }, [companyDetails?.serviceOrientationTypeId]);

    const fetchTaxonomyOptionsMemoized = useCallback(
        () => fetchTaxonomyOptions(orientationType),
        [fetchTaxonomyOptions, orientationType],
    );

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Formik
            initialValues={{
                companyServices,
            }}
            validationSchema={companyInformationSchema()}
            onSubmit={(values) => onSubmit(values)}
            enableReinitialize
        >
            {({ dirty }) => {
                return (
                    <>
                        <Form id="company-services-form">
                            <CompanyServicesDisplay
                                fetchTaxonomyOptions={fetchTaxonomyOptionsMemoized()}
                                setIsDirty={setIsDirty}
                                setIsValid={setIsValid}
                            />
                        </Form>
                        <Prompt when={dirty && !isSaving} />
                    </>
                );
            }}
        </Formik>
    );
};
export default CompanyServicesContainer;

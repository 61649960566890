import { Popup } from "@maistro/components";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Loader } from "components";
import TitleCtaType from "components/Layout/types/TitleCtaType";
import useNavTabs from "features/project/hooks/useNavTabs";
import useProjectFiles from "features/project/hooks/useProjectFiles";
import useProjectResponse from "features/project/hooks/useProjectResponse";
import ProjectResponseDisplay from "features/project/projectResponse/ProjectResponseDisplay";
import useAppDispatch from "hooks/useAppDispatch";
import useCurrentUser from "hooks/useCurrentUser";
import { buildPath } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import dateTimeService from "services/dateTimeService";
import { setBack, setPageTitle, setSecondaryNavItems, setTitleCtas } from "store/layoutSlice";
import ProjectType from "types/enums/projects/ProjectType";
import PermissionsEnum from "types/enums/rolesPermissions/PermissionsEnum";
import SupplierSubmissionStatus from "types/enums/supplierSubmissions/SupplierSubmissionStatus";

const ProjectResponseContainer: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { state } = useLocation();
    const { supplierTabItems } = useNavTabs();
    const [isCancelResponsePopupOpen, setIsCancelResponsePopupOpen] = useState(false);
    const [isWithdrawResponsePopupOpen, setIsWithdrawResponsePopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { userHasProjectPermission } = useCurrentUser();
    const {
        projectResponseInformation,
        updateSupplierSubmissionStatus,
        isSubmitting,
        isTenderResponseAvailable,
        isFetching,
    } = useProjectResponse();
    const deadlineHasPassed = dateTimeService.dateIsInPast(projectResponseInformation?.tenderResponseDeadline);
    const { projectUuid, responseUuid } = projectResponseInformation;
    const { projectFiles } = useProjectFiles(projectUuid);

    const canAskQuestion = useMemo(() => {
        return userHasProjectPermission(PermissionsEnum.CanAskClarificationQuestion, projectUuid);
    }, [projectUuid, userHasProjectPermission]);

    useEffect(() => {
        setIsLoading(isFetching);
    }, [isFetching]);

    useEffect(() => {
        dispatch(setPageTitle(t("projectResponse.pageTitle")));
        dispatch(setSecondaryNavItems(supplierTabItems));
        if (canAskQuestion) {
            dispatch(
                setTitleCtas([
                    {
                        type: TitleCtaType.AskQuestion,
                        onClick: () =>
                            navigate(
                                buildPath(routes.projectResponses.askClarificationQuestion, {
                                    projectUuid,
                                    responseUuid,
                                }),
                            ),
                    },
                ]),
            );
        }

        if (state) {
            dispatch(
                setBack({
                    route: state.route,
                }),
            );
        } else {
            dispatch(
                setBack({
                    route: routes.common.dashboard,
                }),
            );
        }

        return () => {
            dispatch(setSecondaryNavItems(null));
            dispatch(setTitleCtas(null));
        };
    }, [
        canAskQuestion,
        dispatch,
        navigate,
        projectResponseInformation.tenderResponseDeadline,
        projectUuid,
        responseUuid,
        state,
        supplierTabItems,
        t,
    ]);

    const updateSupplierStatus = () => {
        if ([ProjectType.TechnologyQuote, ProjectType.Quote].includes(projectResponseInformation.projectType)) {
            if (projectResponseInformation.status !== SupplierSubmissionStatus.Submitted) {
                updateSupplierSubmissionStatus(
                    SupplierSubmissionStatus.Responding,
                    buildPath(routes.projectResponses.projectResponse, { projectUuid, responseUuid }),
                    t("projectResponse.api.failedToUpdateStatus"),
                );
                return;
            }

            navigate(buildPath(routes.projectResponses.projectResponse, { projectUuid, responseUuid }));
            return;
        }

        if (isTenderResponseAvailable) {
            navigate(buildPath(routes.projectResponses.tenderQuestions, { projectUuid, responseUuid }));
            return;
        }

        updateSupplierSubmissionStatus(
            SupplierSubmissionStatus.Responding,
            buildPath(routes.projectResponses.tenderQuestions, { projectUuid, responseUuid }),
            t("projectResponse.api.failedToUpdateStatus"),
        );
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <ProjectResponseDisplay
                projectResponseInformation={projectResponseInformation}
                projectFiles={projectFiles}
                cancelProjectResponse={() => setIsCancelResponsePopupOpen(true)}
                withdrawProjectResponse={() => setIsWithdrawResponsePopupOpen(true)}
                updateProjectResponseStatus={updateSupplierStatus}
                deadlineHasPassed={deadlineHasPassed}
            />
            <Popup
                title={t("popups.projectResponse.title")}
                message={t("popups.projectResponse.message")}
                isOpen={isCancelResponsePopupOpen}
                primaryActionText={t("popups.projectResponse.cta.primary")}
                onPrimaryAction={() =>
                    updateSupplierSubmissionStatus(
                        SupplierSubmissionStatus.NotInterested,
                        `/dashboard`,
                        t("projectResponse.api.failedToCancelQuote"),
                    )
                }
                secondaryActionText={t("popups.projectResponse.cta.secondary")}
                onSecondaryAction={() => setIsCancelResponsePopupOpen(false)}
                onClose={() => setIsCancelResponsePopupOpen(false)}
                testid="not-interested-popup"
                disabled={isSubmitting}
            />
            <Popup
                title={t("popups.supplierResponse.withdrawResponse.title")}
                message={t("popups.supplierResponse.withdrawResponse.message")}
                isOpen={isWithdrawResponsePopupOpen}
                primaryActionText={t("popups.supplierResponse.withdrawResponse.cta.primary")}
                onPrimaryAction={() =>
                    updateSupplierSubmissionStatus(
                        SupplierSubmissionStatus.Withdrawn,
                        `/dashboard`,
                        t("projectResponse.api.failedToCancelQuote"),
                    )
                }
                secondaryActionText={t("common.cancel")}
                onSecondaryAction={() => setIsWithdrawResponsePopupOpen(false)}
                onClose={() => setIsWithdrawResponsePopupOpen(false)}
                testid="cancel-quote-popup"
                disabled={isSubmitting}
            />
        </React.Fragment>
    );
};

export default ProjectResponseContainer;

import { SortOrder } from "@maistro/components";

import baseApi from "store/api/baseApi";
import { SearchCompaniesRequestDto } from "types/dtos/company/SearchCompaniesRequestDto";
import { SearchCompaniesResponseDto } from "types/dtos/company/SearchCompaniesResponseDto";

const companiesApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        searchCompanies: build.query<SearchCompaniesResponseDto, SearchCompaniesRequestDto>({
            query: (request) => {
                const params = new URLSearchParams();

                params.set("skip", (request.skip ?? 0).toString());
                params.set("take", (request.take ?? 10).toString());

                if (request.sortColumn) {
                    params.set("orderByColumn", encodeURIComponent(request.sortColumn));
                }

                if (request.sortColumn) {
                    params.set("sortDescending", (request.direction === SortOrder.Descending).toString());
                }

                if (request.searchQuery) {
                    params.set("searchQuery", encodeURIComponent(request.searchQuery));
                }

                if (request.searchByEmail) {
                    params.set("searchByEmail", encodeURIComponent(request.searchByEmail));
                }

                if (request.excludeSupplierListBuyerUuid) {
                    params.set("excludeSupplierListBuyerUuid", request.excludeSupplierListBuyerUuid);
                }

                request.serviceOrientationTypeIds?.forEach((id) =>
                    params.append("serviceOrientationTypeIds", id.toString()),
                );

                request.industryTypeIds?.forEach((id) => params.append("industryTypeIds", id.toString()));

                request.companyTypes?.forEach((companyType) =>
                    params.append("companyTypes", encodeURIComponent(companyType)),
                );

                request.statuses?.forEach((status) => params.append("statuses", encodeURIComponent(status)));

                return {
                    url: `/companies/search?${params.toString()}`,
                    method: "GET",
                };
            },
        }),
    }),
    overrideExisting: false,
});

export const { useLazySearchCompaniesQuery } = companiesApi;

export default companiesApi;

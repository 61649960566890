import { Button, Copy } from "@maistro/components";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { ICommonProps } from "components/shared";
import useProjectResponse from "features/project/hooks/useProjectResponse";
import useAppDispatch from "hooks/useAppDispatch";
import { startCase } from "lodash";
import ReactGA from "react-ga4";
import routes from "routes/routePaths/RoutePaths";
import { setBack, setPageTitle } from "store/layoutSlice";
import { ITheme } from "styles/themes/types";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";

const useStyles = createUseStyles((theme: ITheme) => ({
    button: {
        marginTop: theme.spacing.xLarge,
    },
}));

interface ISupplierResponseThankYouProps extends ICommonProps {}

const SupplierResponseThankYou: React.FC<ISupplierResponseThankYouProps> = () => {
    const classes = useStyles();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { projectResponseInformation } = useProjectResponse();

    useEffect(() => {
        dispatch(setPageTitle(t("supplierResponse.thankYou.title")));
        dispatch(setBack(null));
    }, [dispatch, t]);

    return (
        <React.Fragment>
            <Copy>
                {t("supplierResponse.thankYou.subtitle", {
                    projectType: startCase(projectResponseInformation.projectType).toLocaleLowerCase(),
                })}
            </Copy>
            <Button
                label={t("supplierResponse.thankYou.cta")}
                onClick={() => {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: "Supplier Response - Thank You",
                    });
                    navigate(routes.common.dashboard.path);
                }}
                className={classes.button}
                testid="back-to-dashboard-button"
            />
        </React.Fragment>
    );
};

export default SupplierResponseThankYou;

import { IButtonProps } from "@maistro/components/dist/esm/types/components/Button/Button";
import { uniq } from "lodash";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useProject from "features/project/hooks/useProject";
import { IProjectInformation } from "features/project/types";
import useAppDispatch from "hooks/useAppDispatch";
import useCompany from "hooks/useCompany";
import useCurrentUser from "hooks/useCurrentUser";
import usePolicies from "hooks/usePolicies";
import ReactGA from "react-ga4";
import { buildPath, buildRoute } from "routes/helpers/RoutesHelper";
import routes from "routes/routePaths/RoutePaths";
import { resetLayout, setBack, setCtas, setMobileFooterCtas, setPageTitle, setTooltip } from "store/layoutSlice";
import { clearUnSelected } from "store/supplierMatchingSlice";
import { PotentialSupplierDto } from "types/dtos/projectSuppliers/PotentialSupplierDto";
import FeatureToggle from "types/enums/companies/FeatureToggle";
import SupplierTieringStatus from "types/enums/companies/SupplierTieringStatus";
import ga4Action from "types/enums/ga4/ga4EventAction";
import ga4Category from "types/enums/ga4/ga4EventCategory";
import ProjectStatus from "types/enums/projects/ProjectStatus";
import ProjectType from "types/enums/projects/ProjectType";

const useSupplierMatching = (projectInformation: IProjectInformation, projectUuid?: string) => {
    const { userIsMaistro, myCompanyUuid, myUuid } = useCurrentUser();
    const { createOrUpdateProject } = useProject();

    const [companyUuid, setCompanyUuid] = useState(myCompanyUuid);
    const [userUuid, setUserUuid] = useState(myUuid);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (userIsMaistro) {
            setCompanyUuid(projectInformation.clientCompanyUuid);
            setUserUuid(projectInformation.sponsorUserUuid ?? "");
        }

        return () => {
            dispatch(clearUnSelected());
        };
    }, [dispatch, projectInformation.clientCompanyUuid, projectInformation.sponsorUserUuid, userIsMaistro]);

    const [tieringsToReview, setTieringsToReview] = useState<SupplierTieringStatus[]>([]);

    const navigate = useNavigate();

    const { t } = useTranslation();

    const { companyHasFeature, clientHasFeature } = useCompany();
    const { unapprovedTieringStatuses } = usePolicies(companyUuid, userUuid);

    const checkPoliciesAgainstSupplierTierings = useCallback(
        async (supplierTieringStatuses: SupplierTieringStatus[]) => {
            const CompanyHasApprovalsFeature = async (companyUuidToUse: string) => {
                let hasApprovals = false;
                if (userIsMaistro) {
                    hasApprovals = (await clientHasFeature(companyUuidToUse, FeatureToggle.Approvals)) ?? false;
                } else {
                    hasApprovals = companyHasFeature(FeatureToggle.Approvals);
                }
                return hasApprovals;
            };

            if (await CompanyHasApprovalsFeature(companyUuid || "")) {
                const policiesRequiringReview = unapprovedTieringStatuses.filter((status) =>
                    supplierTieringStatuses.includes(status),
                );
                setTieringsToReview(policiesRequiringReview);
            }
        },
        [clientHasFeature, companyHasFeature, companyUuid, unapprovedTieringStatuses, userIsMaistro],
    );

    const dispatchTooltip = useCallback(() => {
        const titleTooltipContent = [
            {
                text: t("projectMatchingScreen.tooltips.matchRating"),
                fieldName: t("projectMatchingScreen.tooltips.matchRatingTitle"),
            },
            {
                text: t("projectMatchingScreen.tooltips.vettedStatus"),
                fieldName: t("projectMatchingScreen.tooltips.vettedStatusTitle"),
            },
        ];
        dispatch(setTooltip(titleTooltipContent));
    }, [dispatch, t]);

    const dispatchSetBack = useCallback(() => {
        dispatch(
            setBack({
                route: buildRoute(routes.projects.projectBrief, { projectUuid }),
            }),
        );
    }, [dispatch, projectUuid]);

    const continueWithInvite = (projectType: ProjectType | undefined) => {
        if (projectType === ProjectType.Tender) {
            navigate(buildPath(routes.projects.sections, { projectUuid }));
            return;
        }

        if (projectInformation.status === ProjectStatus.Create) {
            createOrUpdateProject({ ...projectInformation, status: ProjectStatus.Invite }, { status: true }).then(
                () => {
                    navigate(buildPath(routes.projects.summary, { projectUuid }));
                },
            );
        } else {
            navigate(buildPath(routes.projects.summary, { projectUuid }));
        }
    };

    const dispatchCta = useCallback(
        (
            projectType: ProjectType,
            totalSuppliers: number,
            selectedSuppliers: number,
            showWarning: boolean,
            setIsTieringPopupOpen: Dispatch<SetStateAction<boolean>>,
        ) => {
            const continueButtonProps: IButtonProps = {
                label: "",
                chevron: true,
            };

            continueButtonProps.onClick = () => {
                if (continueButtonProps.testid === "create-questions-button") {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Match - Create Questions`,
                    });
                } else if (continueButtonProps.testid === "invite-to-quote-button") {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Match - Invite To Quote`,
                    });
                } else if (continueButtonProps.testid === "invite-to-technology-quote-button") {
                    ReactGA.event({
                        category: ga4Category.Button,
                        action: ga4Action.ButtonClick,
                        label: `Match - Invite To Technology Quote`,
                    });
                }
                if (showWarning) {
                    setIsTieringPopupOpen(true);
                } else {
                    continueWithInvite(projectType);
                }
            };

            if (projectType === ProjectType.Tender) {
                continueButtonProps.label = t("projectMatchingScreen.cta.tender");
                continueButtonProps.testid = "create-questions-button";
            }

            if (projectType === ProjectType.Quote) {
                continueButtonProps.label = t("projectMatchingScreen.cta.quote");
                continueButtonProps.testid = "invite-to-quote-button";
            }

            if (projectType === ProjectType.TechnologyQuote) {
                continueButtonProps.label = t("projectMatchingScreen.cta.quote");
                continueButtonProps.testid = "invite-to-technology-quote-button";
            }

            if (selectedSuppliers > 0) {
                continueButtonProps.notifications = selectedSuppliers;
                dispatch(setCtas([continueButtonProps]));
                dispatch(setMobileFooterCtas([continueButtonProps]));
            } else if (totalSuppliers > 0) {
                continueButtonProps.disabled = true;
                dispatch(setCtas([continueButtonProps]));
                dispatch(setMobileFooterCtas([continueButtonProps]));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [projectUuid],
    );

    const dispatchLayout = useCallback(
        (
            totalSuppliers: PotentialSupplierDto[],
            selectedSuppliers: string[],
            setIsTieringWarningPopUpOpen: Dispatch<SetStateAction<boolean>>,
        ) => {
            const projectType = projectInformation.type as ProjectType;
            dispatch(resetLayout());
            dispatchSetBack();

            if (totalSuppliers.length > 0) {
                dispatch(setPageTitle(t("projectMatchingScreen.pageTitleSuccess")));
                dispatchTooltip();
            } else {
                dispatch(setPageTitle(t("projectMatchingScreen.noMatches.title")));
            }

            const supplierTierings = uniq(
                totalSuppliers
                    .filter((x) => selectedSuppliers.includes(x.companyUuid))
                    .map((x) => x.supplierTieringStatus),
            );

            checkPoliciesAgainstSupplierTierings(supplierTierings);

            if (projectType !== null) {
                dispatchCta(
                    projectType,
                    totalSuppliers.length,
                    selectedSuppliers.length,
                    tieringsToReview.length > 0,
                    setIsTieringWarningPopUpOpen,
                );
            }
        },
        [
            projectInformation.type,
            dispatch,
            dispatchSetBack,
            checkPoliciesAgainstSupplierTierings,
            t,
            dispatchTooltip,
            dispatchCta,
            tieringsToReview.length,
        ],
    );

    return {
        dispatchLayout,
        continueWithInvite,
        tieringsToReview,
    };
};

export default useSupplierMatching;

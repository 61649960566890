import {
    Button,
    CheckboxesField,
    Copy,
    Drawer,
    Heading,
    SelectField,
    TextButton,
    TextField,
} from "@maistro/components";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";

import { ICommonProps, IOptionProps } from "components/shared";
import { ITheme } from "styles/themes/types";
import { PolicyDto } from "types/dtos/company/policies/PolicyDto";
import PolicyType from "types/enums/companies/Approvals/PolicyType";
import CurrencyType from "types/enums/CurrencyType";
import ProjectType from "types/enums/projects/ProjectType";

const useStyles = createUseStyles((theme: ITheme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium,
    },
    spacing: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.large,
    },
    buttons: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing.large,
    },
}));

interface IPolicyDrawerProps extends ICommonProps {
    groupUuid: string;
    policy: PolicyDto | undefined;
    companyUsers: IOptionProps[];
    isOpen: boolean;
    onSubmit: (values: PolicyDto) => Promise<void>;
    onDelete: () => void;
    onClose: (dirty: boolean) => void;
}

const PolicyDrawer: React.FC<IPolicyDrawerProps> = ({
    groupUuid,
    policy,
    companyUsers,
    isOpen,
    onSubmit,
    onDelete,
    onClose,
}) => {
    const classes = useStyles();
    const [isDirty, setIsDirty] = useState(false);

    const onDrawerClose = () => {
        onClose(isDirty);
    };

    const { t } = useTranslation();

    return (
        <Drawer
            contentClassName={classes.container}
            isOpen={isOpen}
            onClose={onDrawerClose}
            fullScreen
            testid="policy-drawer"
        >
            <Heading variant="h2">{t("userGroups.policies.title")}</Heading>
            <Copy>{t("userGroups.policies.subtitle")}</Copy>
            <Formik
                initialValues={{
                    id: policy?.id ?? "",
                    groupUuid: policy?.groupUuid ?? groupUuid,
                    projectTypes: policy?.projectTypes ?? [],
                    name: policy?.name ?? "",
                    spendLimit: {
                        policyUuid: policy?.id ?? "",
                        currency: policy?.spendLimit?.currency ?? CurrencyType.GBP,
                        limit: policy?.spendLimit?.limit ?? 0,
                    },
                    approverUuids: policy?.approverUuids ?? [],
                    type: PolicyType.SpendLimit,
                    softApproval: policy?.softApproval ?? false,
                    customMessage: policy?.customMessage ?? "",
                    supplierTierings: policy?.supplierTierings ?? [],
                }}
                validationSchema={Yup.object({
                    projectTypes: Yup.array()
                        .of(Yup.mixed<ProjectType>())
                        .required(t("userGroups.policies.form.projectType.required"))
                        .min(1, t("userGroups.policies.form.projectType.required")),
                    name: Yup.string()
                        .required(t("userGroups.policies.form.name.required"))
                        .max(250, t("userGroups.policies.form.name.maximum")),
                    spendLimit: Yup.object().shape({
                        currency: Yup.mixed<CurrencyType>().required(t("userGroups.policies.form.currency.required")),
                        limit: Yup.number()
                            .required(t("userGroups.policies.form.maxSpend.required"))
                            .test("min", (value, ctx) => {
                                if (value !== undefined && value < 0) {
                                    return ctx.createError({
                                        message: t("userGroups.policies.form.maxSpend.minimum"),
                                    });
                                }
                                return true;
                            }),
                    }),
                    approverUuids: Yup.array()
                        .of(Yup.string())
                        .required(t("userGroups.policies.form.approvers.required"))
                        .min(1, t("userGroups.policies.form.approvers.required"))
                        .max(2, t("userGroups.policies.form.approvers.maximum")),
                })}
                enableReinitialize
                onSubmit={(values) => onSubmit(values)}
            >
                {({ isSubmitting, isValid, values, dirty }) => {
                    setIsDirty(dirty);
                    return (
                        <Form className={classes.spacing} id="policy-form">
                            <CheckboxesField
                                name="projectTypes"
                                label={t("userGroups.policies.form.projectType.label")}
                                required
                                tooltip={t("userGroups.policies.form.projectType.tooltip")}
                                options={[
                                    {
                                        label: t("common.tender"),
                                        value: ProjectType.Tender,
                                    },
                                    {
                                        label: t("common.quote"),
                                        value: ProjectType.Quote,
                                    },
                                    {
                                        label: t("common.technologyQuote"),
                                        value: ProjectType.TechnologyQuote,
                                    },
                                ]}
                                testid="policy-project-type"
                            />
                            <TextField
                                name="name"
                                label={t("userGroups.policies.form.name.label")}
                                required
                                tooltip={t("userGroups.policies.form.name.tooltip")}
                                testid="policy-name"
                            />
                            <div className={classes.spacing}>
                                <SelectField
                                    name="spendLimit.currency"
                                    label={t("userGroups.policies.form.currency.label")}
                                    required
                                    tooltip={t("userGroups.policies.form.currency.tooltip")}
                                    options={[
                                        {
                                            label: "£",
                                            value: CurrencyType.GBP,
                                        },
                                        {
                                            label: "€",
                                            value: CurrencyType.EUR,
                                        },
                                        {
                                            label: "$",
                                            value: CurrencyType.USD,
                                        },
                                    ]}
                                    testid="policy-currency"
                                />
                                <TextField
                                    name="spendLimit.limit"
                                    label={t("userGroups.policies.form.maxSpend.label")}
                                    required
                                    tooltip={t("userGroups.policies.form.maxSpend.tooltip")}
                                    type="price"
                                    currencyType={values.spendLimit.currency}
                                    testid="policy-max-spend"
                                />
                            </div>
                            <SelectField
                                name="approverUuids"
                                label={t("userGroups.policies.form.approvers.label")}
                                required
                                tooltip={t("userGroups.policies.form.approvers.tooltip")}
                                options={companyUsers}
                                multiple
                                testid="policy-approvers"
                            />
                            <div className={classes.buttons}>
                                <Button
                                    label={t("userGroups.policies.savePolicy")}
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={!isValid}
                                    testid="save-policy-button"
                                />
                                {values.id.length > 0 && (
                                    <TextButton
                                        label={t("userGroups.policies.deletePolicy")}
                                        onClick={onDelete}
                                        testid="cancel-policy-button"
                                    />
                                )}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </Drawer>
    );
};

export default PolicyDrawer;
